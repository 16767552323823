import { useSearchAnswerByReq } from "application/hooks/apis/search";
import type { BookDTO } from "api/book/dtos";
import type { RequsetSearchAnswer } from "api/search/dtos";
import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import type SwiperClass from "swiper";
import { searchHistoryAtom } from "../../../recoils/search/atoms";
import { useToast } from "../../libs/toast";
import type { SearchAnswerTypes } from "./types";
import { showToast } from "../../../../providers/ToastProvider";

export function useSearchAnswer(): SearchAnswerTypes {
  const setSearchHistory = useSetRecoilState(searchHistoryAtom);
  const { mutateAsync } = useSearchAnswerByReq();
  const { showError } = useToast();

  const handleSearchAnswer = useCallback(
    async (req: RequsetSearchAnswer, swiper: SwiperClass, data: BookDTO[]) => {
      try {
        const res = await mutateAsync(req);
        if (res) {
          const newAnswerId = res[0].id;
          const index = data!.findIndex((d) => d.qid_HIQDB_online_qna.id === newAnswerId);
          swiper?.slideTo(index, 0);
          setSearchHistory((prev) => [
            ...prev,
            { questionId: req.searchData, answerId: newAnswerId },
          ]);
        }
      } catch (error) {
        console.error(error);
        showToast({
          title: "풀이의 정확한 위치를 찾기가 어려워요.",
          message: "첫페이지로 돌아가 수동으로 풀이를 찾아주세요.",
          type: "warning",
        });
      }
    },
    []
  );

  return {
    handleSearchAnswer,
  };
}
