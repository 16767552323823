import { PdfItem, PdfTypeName } from "api/pdfView";
import { ReactComponent as DownloadIcon } from "asset/images/icon/download.svg";
import { ReactComponent as FullListIcon } from "asset/images/icon/full-list.svg";
import { ReactComponent as MoreIcon } from "asset/images/icon/more-horizontal.svg";
import { ReactComponent as RotateIcon } from "asset/images/icon/rotate.svg";
import store from "asset/store";
import { showToast } from "providers/ToastProvider";
import React, { useState } from "react";
import styled from "styled-components";

type PdfItemSubMenuProps = {
  imageSrc?: string;
  currentSliderInfo?: PdfItem;
  slideNumber?: number;
  type?: PdfTypeName;
  handleReloadImage?: () => void;
};
const PdfItemSubMenu = ({
  currentSliderInfo,
  slideNumber,
  imageSrc,
  type,
  handleReloadImage,
}: PdfItemSubMenuProps) => {
  const href = store.isMobile()
    ? `http://native_download?filename=${slideNumber}.jpg?filepath=${imageSrc}`
    : imageSrc;

  const [open, setOpen] = useState(false);

  return (
    <Container>
      <IconContainer onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconContainer>
      {open && (
        <>
          <SubmenuMask onClick={() => setOpen(false)} />
          <SubmenuContainer>
            <li>
              <a
                href={href}
                download
                onClick={() =>
                  showToast({
                    title: "다운로드",
                    message: "이미지를 다운로드 합니다.",
                    type: "success",
                  })
                }
              >
                현재 페이지 다운로드 <DownloadIcon />
              </a>
            </li>
            <ImageModify
              imageSrc={imageSrc}
              slideNumber={slideNumber}
              type={type}
              currentSliderInfo={currentSliderInfo}
              handleReloadImage={handleReloadImage}
            />
            <li>
              전체 페이지 <FullListIcon />
            </li>
          </SubmenuContainer>
        </>
      )}
    </Container>
  );
};

const ImageModify = ({ currentSliderInfo, handleReloadImage }: PdfItemSubMenuProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <li onClick={() => setOpen(true)}>
        이미지 편집 <RotateIcon />
      </li>
      {/*<ImageRotateModal*/}
      {/*  currentSliderInfo={currentSliderInfo}*/}
      {/*  open={open}*/}
      {/*  onClose={() => setOpen(false)}*/}
      {/*  onSuccess={() => {*/}
      {/*    setOpen(false);*/}
      {/*    handleReloadImage();*/}
      {/*  }}*/}
      {/*  title={currentSliderInfo.qid_HIQDB_online_qna.title}*/}
      {/*/>*/}
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 34px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const IconContainer = styled.div`
  cursor: pointer;
  border-radius: 5px;
  opacity: 0.45;
  display: flex;
  justify-content: center;
  padding: 4px;
  align-items: center;
  z-index: 1;
  width: 24px;
  height: 24px;
  background: var(--Default-Gray-90, #262626);
  @media screen and (min-width: 820px) {
    right: 42px;
  }
  @media screen and (min-width: 1180) {
    right: 58px;
    padding: 4px;
  }
  svg {
    @media screen and (min-width: 820px) {
      width: 100%;
      height: 100%;
    }
  }
  > a {
    display: inline-flex;
  }
`;

const SubmenuContainer = styled.ul`
  margin-top: 12px;
  padding-inline-start: 0;
  border-radius: 5px;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  background-color: white;
  overflow: hidden;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: #fff;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  > li {
    background: var(--Default-Gray-90, #262626);
    padding: 8px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    > a {
      color: inherit;
      text-decoration: none;
    }
    svg {
      fill: white;
      stroke: white;
      width: 16px;
      height: 16px;
    }
  }
`;

const SubmenuMask = styled.div`
  position: fixed;
  inset: 0;
`;
export default PdfItemSubMenu;
