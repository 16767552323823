import { Drawer } from "@mui/material";
import { PdfType, usePdfList } from "api/pdfView";
import { useStudyBookInfo } from "api/studyBook";
import constants from "asset/constants";
import { ReactComponent as ShareIcon } from "asset/images/icon/share-icon.svg";
import { ReactComponent as TalkIcon } from "asset/images/icon/talk.svg";
import StudybookIcon from "asset/images/studybook-default.svg";
import util from "asset/util";
import { FontStyleMap } from "components/atoms/Font";
import Section from "components/atoms/Section";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { BookType } from "types/enum";
import AnswerAdd from "./components/AnswerAdd";
import QuestionAddModal from "./components/QuestionAdd";
import StudyBookDelete from "./components/StudyBookDelete";
import StudyBookRename from "./components/StudyBookRename";
import StudyBookType from "./components/StudyBookType";
import { useRecoilState } from "recoil";
import { sideMenuAtom } from "../../../../application/recoils/ui/atoms";

export const getUserBookState = ({ type, status }: { type: number; status: number }) => {
  if (type === 5) {
    return "교재 관리자";
  } else if (type === 6 && status === 1) {
    return "교재 참여자";
  } else if (type === 9 && status === 5) {
    return "초대 승낙 대기 상태";
  } else if (type === 6 && status === 0) {
    return "참여 승낙 대기 상태";
  } else if ((type === 4 || type === 8) && status === 0) {
    return "구독 대기자";
  } else if ((type === 4 || type === 8) && status === 2) {
    return "구독 열람자";
  } else if ((type === 4 || type === 8) && status === 1) {
    return "구독자";
  } else if (type === 0) {
    return "교재 열람자";
  } else if (type === 2) {
    return "교재 참여자";
  } else {
    return "방문자";
  }
};
const SideMenu = () => {
  const [open, setOpen] = useState(false);
  const [isSideMenu, setIsSideMenu] = useRecoilState(sideMenuAtom);
  const { bookId } = useParams();
  const { data } = useStudyBookInfo(bookId);
  const bookType = useMemo(() => {
    return data?.getBook?.book?.book_type;
  }, [data]);
  // @todo pdf교재 말고 일반교재일땐 따로 분기해줘야함
  const { data: list } = usePdfList({ bookId, isAnswer: PdfType.question });
  const questionCount = useMemo(() => list?.qnaList.length, [list]);

  const bookCoverImageUrl = useMemo(() => {
    const { img, id } = data?.getBook?.book ?? {};
    return img || id
      ? `${constants.s3_url}/bookCover/${img ?? id}.jpg?t=${util.getRandomInt(1000, 9999)}`
      : "";
  }, [data]);

  const registDateString = useMemo(() => {
    const date = data?.getBook?.book?.reg_date;
    return dayjs.unix(date).format("YYYY.MM.DD");
  }, [data?.getBook?.book?.reg_date]);

  const userBookState = useMemo(() => {
    return getUserBookState({ type: bookType, status: data?.getBook?.book?.status });
  }, [data]);

  const closeSideMenu = useCallback(() => {
    setIsSideMenu(false);
  }, []);

  return (
    <>
      <Drawer
        open={isSideMenu}
        onClose={closeSideMenu}
        anchor="right"
        keepMounted
        PaperProps={{
          style: {
            borderRadius: "6px 0px 0px 6px",
            boxShadow: "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)",
            background: "#fff",
            padding: "40px 20px 0",
            display: "flex",
            minWidth: 128,
            width: "80vw",
            maxWidth: 254,
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            boxSizing: "border-box",
          },
        }}
      >
        <Section gap={16} direction="column" align="center">
          <CoverImageContainer>
            <img
              src={bookCoverImageUrl}
              onError={(event) => (event.currentTarget.src = StudybookIcon)}
            />
          </CoverImageContainer>
          <UserBookStateLabel>{userBookState}</UserBookStateLabel>
          <InfoFontContainer>
            <TalkIcon />
            {BookType[bookType]}
          </InfoFontContainer>
          <InfoFontContainer>
            <ShareIcon />
            공유받은 교재
          </InfoFontContainer>
          <InfoFontContainer>{`총 ${questionCount?.toLocaleString() ?? 0} 개의 문항`}</InfoFontContainer>
          <DateFontContainer>
            <span>교재생성일</span>
            <span>{registDateString}</span>
          </DateFontContainer>
        </Section>
        <MenuListContaier>
          <li>
            <StudyBookRename />
          </li>
          <li>
            <StudyBookType />
          </li>
          <li>
            <QuestionAddModal />
          </li>
          <li>
            <AnswerAdd />
          </li>
          <li>
            <StudyBookDelete />
          </li>
        </MenuListContaier>
      </Drawer>
    </>
  );
};

const CoverImageContainer = styled.div`
  margin: 0 54px;
  > img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
`;

const UserBookStateLabel = styled.label`
  display: flex;
  height: 24px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 99px;
  background: var(--neutral-color-natural-70, #262626);
  color: var(--black-white-color-white, #fff);
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
`;

const InfoFontContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--neutral-color-natural-60, #434343);
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}

  > svg {
    width: 14px;
    height: 14px;
  }
`;

const DateFontContainer = styled.div`
  ${FontStyleMap["KR/regular/$KR.CAP.REG.12"]}
  gap:8px;
  display: flex;
  color: var(--neutral-color-natural-50, #8c8c8c);
`;

const MenuListContaier = styled.ul`
  margin-top: 58px;
  display: flex;
  gap: 8px;
  padding: 0;
  list-style: none;
  flex-direction: column;
  width: 100%;
`;

export const MenuItemContainer = styled.a`
  padding: 8px;
  border-bottom: 1px solid var(--neutral-color-natural-20, #f0f0f0);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
  }
`;
export default SideMenu;
