import localforage from "localforage";
import type { AtomEffect } from "recoil";

export const localForageEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet, trigger }) => {
    const forage = localforage.createInstance({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, localforage.WEBSQL],
      name: "pdfview",
    });

    if (typeof window === "undefined") {
      return;
    }

    const loadPersisted = async (): Promise<void> => {
      const savedValue = await forage.getItem<any>(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }
    };

    if (trigger === "get") {
      loadPersisted();
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        forage.removeItem(key);
      } else {
        forage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
