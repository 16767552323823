import {
  VideoPlayer as ReactVideoPlayer,
  VideoPlayerProps,
  VideoPlayerState,
} from "@videojs-player/react";
import { CommonContext } from "asset/context";
import store from "asset/store";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import { VideoJsPlayer } from "video.js";
import "video.js/dist/video-js.css";
import PlayerControls from "./PlayerControls";
import PlayerDisplayButtons from "./PlayerDIsplayButtons";
import PrevNextDoubleTaps from "./PrevNextDoubleTaps";
import styles from "./style.module.scss";

interface IVideoContext {
  player: VideoJsPlayer | null;
  playerState: VideoPlayerState | null;
  wrapper: HTMLDivElement | null;
  setPlayerRef?: (ref: VideoJsPlayer) => void;
  handleFullScreen?: () => void;
  fullscreenable: boolean;
  addon?: { button: React.ReactNode; onClick?: () => void };
}
export const VideoContext = createContext<IVideoContext>({
  player: null,
  playerState: null,
  wrapper: null,
  fullscreenable: true,
});

interface IVideoPlayerProps {
  src: string | File;
  onErrorSrc?: string | File;
  poster?: string;
  startTime?: number;
  endTime?: number;
  setPlayerRef?: (ref: VideoJsPlayer) => void;
  onChangeVideoState?: (state: VideoPlayerState) => void;
  handleFullScreen?: () => void;
  fullscreenable?: boolean;
  addon?: { button: React.ReactNode; onClick?: () => void };
}

const VideoPlayer = ({
  poster,
  src,
  onErrorSrc,
  endTime,
  startTime,
  setPlayerRef,
  handleFullScreen,
  fullscreenable = true,
  addon,
  onChangeVideoState,
}: IVideoPlayerProps) => {
  const { isMobile } = store;
  const { isFull } = useContext(CommonContext);
  const playerRef = useRef<VideoJsPlayer>(null);
  const [playerState, setPlayerState] = useState<VideoPlayerState>();
  const [endTimeState, setEndTimeState] = useState<number | undefined>(endTime);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isControllerShow, setIsControllerShow] = useState(false);

  const [config] = useState(() => ({
    playbackRates: [0.5, 0.75, 1, 1.5, 2, 3],
    volume: 1,
  }));
  useEffect(() => {
    if (endTime === 0 && endTimeState !== playerState?.duration) {
      setEndTimeState(playerState?.duration);
    }
  }, [playerState, endTime, endTimeState]);

  const handleMounted: VideoPlayerProps["onMounted"] = (payload) => {
    playerRef.current = payload.player;
    setPlayerState(payload.state);
  };

  const handleStateChange = (state: VideoPlayerState) => {
    setPlayerState(state);
  };

  const handleWrapperClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (isMobile()) {
        if (isControllerShow) {
          // playerState.playing ? playerRef.current?.pause() : playerRef.current?.play();
        } else {
          setIsControllerShow(true);
        }
      } else {
        playerState.playing ? playerRef.current?.pause() : playerRef.current?.play();
        event.stopPropagation();
      }
    },
    [playerState, playerRef.current]
  );

  let timer;
  const resetTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      setIsControllerShow(false);
    }, 3000); // 3초 후에 숨김
  }, [timer]);

  const handleInteraction = useCallback(() => {
    setIsControllerShow(true);
    resetTimer();
  }, [resetTimer]);

  useEffect(() => {
    // 페이지가 로드될 때 타이머 시작
    resetTimer();
  }, [resetTimer]);

  useEffect(() => {
    if (
      playerState?.currentTime < startTime ||
      playerState?.currentTime > (endTimeState ?? playerState?.duration)
    ) {
      playerRef.current?.currentTime(startTime);
    }
  }, [playerState?.currentTime]);

  useEffect(() => {
    if (playerRef?.current) {
      setPlayerRef?.(playerRef?.current);
    }
  }, [playerRef?.current, setPlayerRef]);

  const wrapperFullscreenClass = useMemo(() => (isFull ? styles.full : styles.inherit), [isFull]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onChangeVideoState?.(playerState);
  }, [playerState, onChangeVideoState]);

  return (
    <Container onClick={handleWrapperClick}>
      <VideoContext.Provider
        value={{
          player: playerRef.current,
          playerState,
          wrapper: wrapperRef.current,
          handleFullScreen,
          fullscreenable,
          addon,
        }}
      >
        {src && (
          <ReactVideoPlayer
            className={[wrapperFullscreenClass, styles.videoContainer].join(" ")}
            playsinline
            controls={false}
            src={src as string}
            poster={poster}
            volume={config.volume}
            playbackRates={config.playbackRates}
            onMounted={handleMounted}
            onStateChange={handleStateChange}
            onLoadedMetadata={() => {
              setIsLoading(false);
            }}
            onError={(e: any) => {
              setIsLoading(true);
              setTimeout(() => {
                playerRef.current.src(onErrorSrc as string);
              }, 1000);
            }}
          >
            {({ player, state }) => {
              endTimeState && player.duration(endTimeState);
              return (
                <>
                  <ControlWrapper
                    ref={wrapperRef}
                    onTouchMove={() => {
                      handleInteraction();
                    }}
                    onTouchEnd={() => {
                      handleInteraction();
                    }}
                    onMouseEnter={() => {
                      setIsControllerShow(true);
                    }}
                    onMouseLeave={() => {
                      setIsControllerShow(false);
                    }}
                    style={{
                      opacity: isControllerShow ? 1 : 0,
                    }}
                  >
                    <PrevNextDoubleTaps resetTimer={resetTimer} />
                    <PlayerDisplayButtons />
                    <div className={styles.controllerWrapper}>
                      <PlayerControls />
                    </div>
                  </ControlWrapper>
                  {isLoading && <VideoLoading />}
                </>
              );
            }}
          </ReactVideoPlayer>
        )}
      </VideoContext.Provider>
    </Container>
  );
};

const VideoLoading = () => {
  const loadingStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
  };
  return (
    <UiLoading
      isFull={false}
      isCircle={true}
      text="처리중입니다. 잠시만 기다려주세요."
      container_style={loadingStyle}
    />
  );
};

const ControlWrapper = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 150ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  & .vjs-error-display {
    display: none;
  }
`;
export default VideoPlayer;
