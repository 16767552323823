import type { BookDTO } from "api/book/dtos";
import { getAnswersByBookId, getQuestionsByBookId } from "api/book/services";
import { useCallback } from "react";
import { useBaseQuery } from "../base/use_base_query";
import { QueryKeys } from "../constants";
import type { AnswerByQuestionIdType, BookByIdType } from "./types";

export function useGetQuestionsByBookId(bookId: string): BookByIdType {
  const { data, isError, isFetching, isLoading } = useBaseQuery<BookDTO[]>({
    queryKey: [QueryKeys.BOOK_BY_ID, bookId],
    queryFn: () => getQuestionsByBookId(bookId),
  });

  return { data, isError, isFetching, isLoading };
}

export function useGetAnswersByBookId(bookId: string): AnswerByQuestionIdType {
  const { data, isError, isFetching, isLoading } = useBaseQuery<BookDTO[]>({
    queryKey: [QueryKeys.ANSWER_BY_BOOKID, bookId],
    queryFn: () => getAnswersByBookId(bookId),
  });

  const answerByQuestionId = useCallback(
    (qId: number): BookDTO | undefined => {
      return data?.find((d) => d.qid_HIQDB_online_qna.id === qId);
    },
    [data]
  );

  return { data, answerByQuestionId, isError, isFetching, isLoading };
}
