import { fullSreenAtom } from "application/recoils/ui/atoms";
import { Container } from "@mui/material";
import { useRecoilValue } from "recoil";
import type { BaseLayoutProps } from "./types";
import React from "react";

export default function BaseLayout({ children }: BaseLayoutProps): JSX.Element {
  const isFullScreen = useRecoilValue(fullSreenAtom);

  const Element = isFullScreen ? "div" : Container;

  return (
    <Element
      style={{
        height: "100vh",
        ...(isFullScreen && { maxWidth: "100%", padding: 0 }),
      }}
    >
      {children}
    </Element>
  );
}
