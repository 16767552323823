import { useGetQuestionsByBookId } from "application/hooks/apis/book";
import { LoadingView } from "components/templates/loadingView";
import BookDetail from "presentation/features/detail";
import BaseLayout from "presentation/layouts/base";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MuiProvider } from "../../providers/mui";
import { CommonContext } from "../../asset/context";

export function BookDetailPage(): JSX.Element {
  const { bookId } = useParams();

  const { data, isLoading, isFetching } = useGetQuestionsByBookId(bookId!.toString());
  const { setBookInQuestionList } = useContext(CommonContext);

  useEffect(() => {
    if (data) setBookInQuestionList(data);
  }, [data]);

  if (isLoading || isFetching) {
    return <LoadingView />;
  }

  return (
    <MuiProvider>
      <BaseLayout>
        <BookDetail data={data} />
      </BaseLayout>
    </MuiProvider>
  );
}
