/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import React from "react";

type ToastProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export function ToastProvider(props: ToastProviderProps): JSX.Element {
  const { children } = props;
  return (
    <>
      {children}
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        newestOnTop
        rtl={false}
        autoClose={2000}
        theme="dark"
        closeButton={false}
        icon={({ type }) => {
          if (type === "error") {
            return <img src="/assets/icons/ic_alert_triangle.svg" alt="alert" />;
          }
        }}
      />
    </>
  );
}
