import { answerViewAtom } from "application/recoils/ui/atoms";
import { AnswerSwiper } from "components/organisms/answerSwiper";
import { QuestionSwiper } from "components/organisms/questionSwiper";
import { QuestionToolbar } from "components/organisms/questionToolbar";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import type SwiperClass from "swiper";
import PanelGroupContainer from "../../organisms/panelGroupConatiner";
import type { QnAViewProps } from "./types";
import VariantLayout from "../../../presentation/layouts/variant";

export function QnAView({ data }: QnAViewProps): JSX.Element {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const isAnswerView = useRecoilValue(answerViewAtom);

  return (
    <>
      <QuestionToolbar questionSwiper={swiper!} bookId={data[0]?.bid} />
      <VariantLayout>
        <PanelGroupContainer
          topItem={
            <QuestionSwiper
              questionSwiper={swiper!}
              setSwiper={setSwiper}
              data={data}
              swiper={swiper}
            />
          }
          bottomItem={isAnswerView && <AnswerSwiper bookId={data[0]?.bid} />}
        />
      </VariantLayout>
    </>
  );
}
