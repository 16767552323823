import { atom } from 'recoil';
import { AtomKeys } from '../constants';

export const realIndexAtom = atom<number>({
  key: AtomKeys.SWIPER_SLIDE_REAL_INDEX,
  default: 0,
  // effects: [localForageEffect(AtomKeys.SWIPER_SLIDE_REAL_INDEX)],
});

export const zoomAtom = atom<boolean>({
  key: AtomKeys.SIWPER_IS_ZOOMED,
  default: false,
});
