import type { Theme } from "@mui/material";

type GlobalStyles = (theme: Theme) => Record<string, Record<string, any>>;

export const globalStyles: GlobalStyles = (theme: Theme) => {
  return {
    body: {
      margin: 0,
      padding: 0,
      width: "100vw",
      height: "100vh",
    },

    // swiper
    ".swiper-container, .swiper-slide, .swiper-wrapper": {
      // NOTE: slideTo 지연 방지
      transition: "none !important",
      animation: "none !important",
    },
    ".question-swiper-container": {
      borderRadius: "10px",
      // NOTE: -1을 부여해야 drag시에 answer swiper가 보임
      zIndex: "-1 !important",
    },
    ".question-swiper-container, .answer-swiper-container": {
      "& .swiper-button-next, & .swiper-button-prev": {
        width: "32px",
        height: "100%",
        top: "0",
        opacity: "0",
        transition: "opacity 0.3s ease-out",
        marginTop: "0",
      },
      "& .swiper-button-next": {
        background: `url("/assets/icons/ic_right.svg") no-repeat ${theme.palette.background.paper}`,
        backgroundSize: "24px 24px",
        backgroundPosition: "center",
        right: "0",
      },
      "& .swiper-button-prev": {
        background: `url("/assets/icons/ic_left.svg") no-repeat ${theme.palette.background.paper}`,
        backgroundSize: "24px 24px",
        backgroundPosition: "center",
        left: "0",
      },
      "& .swiper-button-prev.swiper-button-disabled, & .swiper-button-next.swiper-button-disabled":
        {
          opacity: "0",
        },
      "& .swiper-button-next::after, & .swiper-button-prev::after": {
        display: "none",
      },
      ".answer-swiper-container": {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      },

      // toast
      ".Toastify__toast-icon": {
        width: "32px !important",
      },
      ".Toastify__toast-container": {
        width: "340px !important",
        "@media screen and (max-width: 480px)": {
          width: "100vw !important",
        },
      },
    },
  };
};
