import * as Sentry from "@sentry/react";
import { useMyDefaultAcademy } from "api/academy";
import MessageMark from "pages/MessageMark";
import MessageMarkList from "pages/MessageMarkList";
import MyInfo_orderdetail from "pages/MyInfo/MyInfo_orderdetail";
import MyInfo_orderlog from "pages/MyInfo/MyInfo_orderlog";
import Lecture_modi from "pages/Profile/Lecture_modi";
import Profile from "pages/Profile/Profile";
import { Profile_lecture_detail } from "pages/Profile/Profile_lecture_detail";
import Profile_lecture_regi_three from "pages/Profile/Profile_lecture_regi_three";
import Profile_lecture_regi_two from "pages/Profile/Profile_lecture_regi_two";
import Profile_regi from "pages/Profile/Profile_regi";
import { Reply } from "pages/Reply/Reply";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { BookDetailPage } from "./pages/PdfDetail";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router";
import { createRoutesFromChildren, matchRoutes } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import "./App.css";
import Before from "./Before";
import constants from "./asset/constants";
import store from "./asset/store";
import util from "./asset/util";
import Academy_book_List from "./pages/Academy/Academy_book_List";
import Academy_book_folder from "./pages/Academy/Academy_book_folder";
import Academy_book_regi from "./pages/Academy/Academy_book_regi";
import Academy_exist from "./pages/Academy/Academy_exist";
import Academy_folder_list from "./pages/Academy/Academy_folder_list";
import Academy_regi_one from "./pages/Academy/Academy_regi_one";
import Academy_regi_three from "./pages/Academy/Academy_regi_three";
import Academy_regi_two from "./pages/Academy/Academy_regi_two";
import Academy_regi_two_add from "./pages/Academy/Academy_regi_two_add";
import Lecture_list from "./pages/Academy/Lecture_list";
import Board_write from "./pages/Board/Board_write";
import Book from "./pages/Book/Book";
import Book_modi from "./pages/Book/Book_modi";
import Book_share_list from "./pages/Book/Book_share_list";
import Column from "./pages/Column/Column";
import Column_pick_classify from "./pages/Column/Column_Pick_classify";
import Index from "./pages/Column/Column_detail";
import Column_pick_list from "./pages/Column/Column_pick_list";
import Invite from "./pages/Invite/Invite";
import Message_talk from "./pages/Message/Message_talk";
import ViewQna from "./pages/Message/ViewQna";
import Video from "./pages/Video/Video";
import {
  Academy,
  Message,
  Message_Mark_List,
  Register_After,
  Register_Five,
  Register_Four,
  Register_Intro,
  Register_One,
  Register_Three,
  Register_Two,
} from "./pages/page_index";
import UiNoticeDetail from "./uiComponents/UiNotice/UiNoticeDetail";
// import UiCrop from './uiComponents/UiCrop/UiCrop';
import apiFn from "./asset/apiClass";
import Book_Main from "./pages/Book/Book_Main";
import Book_folder from "./pages/Book/Book_folder";
import Book_subs_set from "./pages/Book/Book_subs_set";
import Message_Folder from "./pages/Message/Message_Folder";
import Message_book_list from "./pages/Message/Message_book_list";
import Message_invite from "./pages/Message/Message_invite";
import Message_modi from "./pages/Message/Message_modi";
import Message_talk_review from "./pages/Message/Message_talk_review";
import Message_teacher from "./pages/Message/Message_teacher";
import MyInfo_Profile from "./pages/MyInfo/MyInfo_Profile";
import PaymentMobileRedirect from "./pages/Payment/MobileRedirect";
import People_Main from "./pages/People/People_Main";
import Lecture_Main from "./pages/Profile/Lecture_Main";
import QNA from "./pages/QNA/QNA";
import QnaRegi from "./pages/QNA/QnaRegi";
import Post_Search from "./pages/Search/Post_Search";
import Search from "./pages/Search/Search";
import Warn from "./pages/Warn/Warn";
import Register_chk from "./pages/before/register/Register_chk";
import UiCreate from "./uiComponents/UiCreate/UiCreate";
import Apps from "./uiComponents/UiCrop/App";
import UiLoading from "./uiComponents/UiLoading/UiLoading";

// Import the functions you need from the SDKs you need
import { useLazyQuery } from "@apollo/client";
import { CommonContext, OpenMode, PaymentContext } from "asset/context";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import ClassDetail from "pages/Column/ClassDetail";
import LectureUpdate from "pages/LectureRegist/LectureUpdate";
import LectureVideo from "pages/LectureVideo";
import LectureVideoSearch from "pages/LectureVideoSearch";
import MyInfo_PW from "pages/MyInfo/MyInfo_PW";
import MyInfo_infoModi from "pages/MyInfo/MyInfo_infoModi";
import MyInfo_review from "pages/MyInfo/MyInfo_review";
import MyInfo_ticketlog from "pages/MyInfo/MyInfo_ticketlog";
import Order from "pages/Payment/Order";
import StudyBook from "pages/StudyBook";
import StudyBookDetail from "pages/StudyBookDetail";
import StudyBookFolder from "pages/StudyBookFolder";
import DeactivateAccount from "pages/before/register/DeactivateAccount";
import { CheckAcademyUsePaymentDocument, ForSaleTicketsDocument } from "types/graphql/graphql";
import Book_remove from "./pages/Book/Book_remove";
import LectureRegist from "./pages/LectureRegist";
import LectureVideoList from "./pages/LectureVideoList";
import SelectLecture from "./pages/Message/SelectLecture";
import Myinfo_Main from "./pages/MyInfo/Main/Myinfo_Main";
import Myinfo_Question from "./pages/MyInfo/Question/Myinfo_Question";
import PdfView from "./pages/PdfView";
import QuestionBookSearch from "./pages/Search/QuestionBookSearch";
import QuestionImageSearch from "./pages/Search/QuestionImageSearch";
import Tutorial from "./pages/Tutorial";
import Youtube from "./pages/Youtube";
import ContactUs from "./pages/before/ContactUs";
import type { BookDTO } from "./api/book/dtos";
import PDFdemo from "pages/PDFdemo";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkajSQKxKMPGo87zH5K24Ftzdx75hnyck",
  authDomain: "hiqsum-379810.firebaseapp.com",
  projectId: "hiqsum-379810",
  storageBucket: "hiqsum-379810.appspot.com",
  messagingSenderId: "53857536980",
  appId: "1:53857536980:web:239c63cc3e4a8f95182441",
  measurementId: "G-V4MJVN8N5X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let SentryRoutes = Routes;
if (process.env.NODE_ENV === "NEVER") {
  //invalid hook call 경고가 발생하지 않거나 일부에서만 발생해서 추적이 가능할 때 사용하기로 약속합니다.
  //24년 3월 29일. 김씨 선언.
  Sentry.init({
    dsn: "https://3f6b232232d48cd9344cda8b6f0780ee@o4505665241743360.ingest.sentry.io/4505684099072000",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https://api.hiq24.co.kr/"],
      }),
      new Sentry.Replay(),
    ],
    environment: constants.channelId,
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  SentryRoutes = Sentry.withSentryReactRouterV6Routing(SentryRoutes);
}

function App() {
  let params = useParams();

  store.use("user", {});

  var useLoca = useLocation();

  var [isSide, setIsSide] = useState(false);

  var [isSR_detail, setIsSR_detail] = useState(false);
  const [solutionList, setSolutionList] = useState(null);
  var [ori_data, setOri_data] = useState({});

  // scroll key
  var [scrollKey, setScrolllKey] = useState("");

  const [isLoading, setIsLoading] = useState(false); // 0913 messageList 관련 임시 주석 true -> false
  const [isBlock, setIsBlock] = useState(false);
  var [isDetail, setIsDetail] = useState(false);

  // senderInfo
  const [senderInfo, setSenderInfo] = useState({});

  // video Info
  var [videoInfo, setVideoInfo] = useState({});

  //youtube url
  var [youtube_url, setYoutube_url] = useState("");

  // oriQnaRoom Data
  var [oriQnaRoom, setOriQnaRoom] = useState("");

  // create Data Obj
  var [createObj, setCreateObj] = useState({});

  var [userInfo, setUserInfo] = useState<Record<string, string>>({});

  // Qna Data
  var [qnaData, setQnaData] = useState({});
  useEffect(() => {
    console.log("qnaData : ", qnaData);
  }, [qnaData]);

  // Add Qna Search Data
  const [addSearch, setAddSearch] = useState(null);

  // Home message
  var [messageList, setMessageList] = useState([]);

  // hiddenNav
  var [hiddenNav, setHiddenNav] = useState("");

  // hidden url
  var [hiddenUrl, setHiddenUrl] = useState("");

  // Board Write Object
  var [boardObj, setBoardObj] = useState({
    mosaic: "y",
  });

  var [clipData, setClipData] = useState({});

  // Board Write Cat Obj
  const [cat, setCat] = useState({
    id: "66", // notice Cat id
  });

  // Board Title Temp Data
  var [tempTitleData, setTempTitleData] = useState({
    전체: {
      name: "전체",
      id: 0,
    },
  });

  //
  var [explainData, setExplainData] = useState([]);
  var [explainPage, setExplainPage] = useState(1);

  // File Obj
  var [fileObj, setFileObj] = useState({});

  // 폴더 공유 교재 리스트
  const [s_bookList, setS_BookList] = useState([]);

  // 회원가입
  const [regiData, setRegiData] = useState({});

  // 회원가입(선생님 클래스룸)
  const [t_classData, setT_classData] = useState([]);

  // 클래스룸생성
  const [c_regiData, setC_regiData] = useState({});

  // message_home 교재 분류 아이디
  var [lectureId, setLectureId] = useState("");

  // List
  const [listStat, setListStat] = useState(null);

  // id 저장
  const [id_set, setId_set] = useState("");

  // 삭제된 질문 페이지 교재 id
  const [del_bookdId, setDel_bookId] = useState(null);

  // 삭제된 질문 페이지 교재 sorting 기준
  const [del_sorting, setDel_sorting] = useState("reg_date desc");

  // 수정 값 저장 공통 변수
  var [modiValue, setModiValue] = useState("");

  // 강의생성
  const [l_regiData, setL_regiData] = useState({});
  // 강의시간
  const [l_time, setL_time] = useState({
    월: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
    화: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
    수: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
    목: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
    금: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
    토: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
    일: {
      slc: "N",
      startDay: "",
      endDay: "",
    },
  });

  const getStorage = () => {
    try {
      if (window.localStorage.getItem("user")) {
        // // console.log("$$",JSON.parse(window.localStorage.getItem("user")));
        // store.set("user",JSON.parse(window.localStorage.getItem("user")))
        let _userInfo = JSON.parse(window.localStorage.getItem("user"));
        setUserInfo({
          ..._userInfo,
        });
        // userInfo = _userInfo
      }
    } catch (e) {}
  };

  const [isFull, setIsFull] = useState(false);

  //const [isWs, setIsWs] = useState(false);
  const [isReconnect, setIsReconnect] = useState(false);
  const [msgCount, setMsgCount] = useState(0);

  // 질문권/결제 모달
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [ticketInventories, setTicketInventories] = useState([]);
  const [updatedAt, setForceUpdate] = useState(new Date().getTime());
  const [tickets, setTickets] = useState([]);
  const [paymentOpen, setPaymentOpen] = useState<OpenMode>("none");
  const [pickResult, setPickResult] = useState<boolean | string>(false);
  const [isSelectTeacherModalOpen, setIsSelectTeacherModalOpen] = useState<boolean>(false);
  const [selectedLectureId, setSelectedLectureId] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [defaultAcademyId, setDefaultAcademyId] = useState("");
  const [bookInQuestionList, setBookInQuestionList] = useState<BookDTO[]>(null);

  const requestPickTicket = useCallback(() => {
    if (paymentOpen != "none") {
      setPickResult(false);
      setPaymentOpen("select");
      setPaymentModalOpen(true);
    } else {
      // 학원에서 결제를 사용하지 않을 때, 기본 질문권을 사용
      setPickResult("BASIC");
    }
  }, [paymentOpen]);

  // 학원 id 저장
  const [academyId, setAcademyId] = useState<string>("");
  const { data: defaultAcademyIdData } = useMyDefaultAcademy();
  const classroomId = constants.classroomId;

  useEffect(() => {
    if (!classroomId) {
      if (!defaultAcademyIdData) {
        return;
      }
      setDefaultAcademyId(defaultAcademyIdData.getDefaultAcademy?.academy?.id);
      setAcademyId(defaultAcademyIdData.getDefaultAcademy?.academy?.id);
    } else {
      setAcademyId(String(classroomId));
    }
  }, [defaultAcademyIdData, userInfo]);

  const [checkAcademyUsePayment, { data: academyCheckData }] = useLazyQuery(
    CheckAcademyUsePaymentDocument
  );

  const [queryForSaleTickets, { data: ticketData }] = useLazyQuery(ForSaleTicketsDocument);

  useEffect(() => {
    if (academyId) {
      checkAcademyUsePayment({
        variables: { academyId },
        fetchPolicy: "network-only",
      });
    }
  }, [academyId]);

  useEffect(() => {
    // 결제 기능이 켜져있다면
    if (academyId && academyCheckData?.checkAcademyUsePayment) {
      setPaymentOpen("both");
      // 사용되는 질문권 목록 가져오기
      queryForSaleTickets();
      if (ticketData?.forSaleTickets) {
        const tickets = ticketData.forSaleTickets;
        setTickets(tickets);
      }
    } else if (!academyId || !academyCheckData?.checkAcademyUsePayment) {
      setPaymentOpen("none");
    }
  }, [academyCheckData, setPaymentOpen, academyId, queryForSaleTickets, ticketData]);

  let ws = useRef(null);
  var webSocketUrl = useRef(null);
  const isVisible = usePageVisibility();

  const stack = store.use("stack", []);
  const { pathname } = useLocation();

  const windowAny = window as any;
  const documentAny = document as any;
  const type = useNavigationType();
  // console.log(stack)

  useEffect(() => {
    if (type === "POP") {
      // const lastVisit = stack[stack.length - 1]
      // if (!lastVisit) {
      //     navigate('/')
      //     return
      // }
      store.set("stack", stack.slice(0, stack.length - 1));
    } else if (type === "PUSH") {
      store.set("stack", [...stack, pathname]);
      if (!pathname.includes("/talk")) {
        setT_page(1);
        setMessage_page(1);
      }
    } else {
      store.set("stack", [...stack.slice(0, stack.length - 1), pathname]);
    }
  }, [pathname, type]);

  useEffect(() => {
    console.log("scrollKey", scrollKey);
  }, [scrollKey]);

  const fn = async ({ api_ = async () => {} }) => {
    setIsBlock(true);
    try {
      await api_();
    } catch (e) {
      //console.log(e)
    } finally {
      setIsBlock(false);
    }
  };

  useEffect(() => {
    console.log("isVisible => ", isVisible);
    try {
      if (isVisible) {
        connectWs();
      } else {
        // store.set('reconnect', false);

        setIsReconnect(false);
        ws.current.close();

        ws.current = null;
      }
    } catch (e) {
      // console.log("socket close Error => ",e);
    }
  }, [isVisible]);

  const connectWs = () => {
    try {
      if (!userInfo["id"]) return;
      if (!webSocketUrl.current) webSocketUrl.current = constants.wsurl + "/" + userInfo["id"];
      if (ws.current && ws.current.readyState < 3) return;
      console.log("Connect!!! ", webSocketUrl.current);
      ws.current = new WebSocket(webSocketUrl.current);
      ws.current.onopen = () => {};
      ws.current.onclose = (error) => {
        console.log("isReconnect :", isReconnect);
        console.log("### ", error);
        if (isReconnect) {
          connectWs();
        } else {
          ws.current = null;
        }
        if (!userInfo["id"]) {
          return;
        }
        if (error.code == 1006) {
          setTimeout(() => connectWs(), 1000);
        }
        setIsReconnect(false);
      };
      ws.current.onerror = (error) => {
        setIsReconnect(false);
      };
      ws.current.onmessage = (evt) => {
        if (!window.location.pathname.includes("talk")) {
          window.scrollTo(0, 0);
          let chk;
          if (window.localStorage.getItem("msg_home")) {
            chk = JSON.parse(window.localStorage.getItem("msg_home"));
          }
          if (window.location.pathname.includes("student")) {
            if (chk) {
              apiPro_counts("", 1, false, true);
            } else {
              apiPro_counts("", 1, false, false);
            }
          } else {
            if (chk) {
              apiPro_counts("Y", 1);
            } else {
              apiPro_counts("", 1);
            }
          }
        }
      };
    } catch (e) {
      console.error("connectFail", e);
    }
  };

  var [message_page, setMessage_page] = useState(1);
  var [t_page, setT_page] = useState(0);
  var [tcnt, setTcnt] = useState(0);
  const apiPro_counts = async (chk, page, isChk?, isConfirm?, cid?, listStat?) => {
    try {
      let amount = 20;

      let pass = true;
      if (messageList.length > 0) {
        if (page) {
          console.log("1");
          if (tcnt <= page * amount) {
            console.log("2");
            pass = false;
          } else {
            console.log("3");
            pass = true;
          }
        } else {
          console.log("4");
          if (message_page) {
            console.log("%");
            console.log("MESSAGE LIST TCNT : ", tcnt);
            console.log("message_page : ", message_page);
            console.log("amount : ", amount);
            if (tcnt <= (message_page - 1) * amount) {
              console.log("6");
              pass = false;
            } else {
              console.log("8");
              pass = true;
            }
          }
        }
      } else {
        setIsLoading(true);
      }

      if (isChk) {
        pass = true;
        page = 1;
      }
      if (t_page > 0) {
        pass = true;
        setT_page(0);
      }

      if (!pass) return;

      let _obj = {
        cat: "hiq",
        // cmd : "messageAll",
        cmd: "newMessageList",
        classroomId: constants.classroomId,
        id: userInfo["id"] ? userInfo["id"] : JSON.parse(window.localStorage.getItem("user"))?.id,
        sort: "reg_date desc",
        type: userInfo.type ?? JSON.parse(window.localStorage.getItem("user"))?.type,
        isConfirm: window.location.pathname.includes("student") ? (isConfirm ? "Y" : "") : "",
        stat: listStat ? listStat : window.localStorage.getItem("msg_home_section"),
        isLive: chk === "Y" ? "Y" : "",
        // isLive : "Y",
        isHide: window.localStorage.getItem("msg_home_section")
          ? window.localStorage.getItem("msg_home_section") == "d"
            ? "Y"
            : ""
          : "",
        cid: cid
          ? cid === "all"
            ? ""
            : cid
          : window.localStorage.getItem("msg_home_lc")
            ? window.localStorage.getItem("msg_home_lc") === "all"
              ? ""
              : window.localStorage.getItem("msg_home_lc")
            : "",
        page: 1,
        amount: page ? Number(page) * amount : Number(message_page) * amount,
      };
      let { list, tcnt: newTcnt } = await store.axiosPost(_obj);
      setIsLoading(false);

      setTcnt(newTcnt);

      setMessage_page((prevState) => prevState + 1);
      setMessageList(list);

      console.log("_data", list);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    console.log("addSearch", addSearch);
  }, [addSearch]);

  useEffect(() => {
    document.addEventListener("message", async (event) => {
      const e = event as any;
      // console.log(e.data)
      // alert(e.data)
      if (e.data == "isModal") {
        // setIsEdit(false);
      }
      if (e.data == "background") {
        setIsReconnect(false);
        ws.current?.close();

        ws.current = null;
      }
    });
    window.addEventListener("beforeunload", (e) => {
      setIsReconnect(false);
      ws.current?.close();
      ws.current = null;
    });
    if (windowAny.ReactNativeWebView) {
      if (!util.chk_platform()) {
        window.addEventListener("message", async (e) => {
          try {
            // alert(JSON.stringify(e))
            // alert(JSON.stringify(e.data))
            let data = JSON.parse(e.data);

            // if(data["msg"] == "back"){

            // }
            // alert(JSON.stringify(data))
            // console.log(data)
            let isOk = await apiFn.providerSignIn({
              state: {
                apple: {
                  id: data["user"],
                },
              },
            });
            // alert("1")
            if (isOk.status === -1) {
              // alert("2")
              let appleObj = {
                email: data["email"],
                name: data["fullName"]["familyName"] + data["fullName"]["givenName"],
                oid: data["user"],
              };
              setRegiData({
                ...appleObj,
              });
              // setUserInfo({
              //     ...p_userInfo
              // })
              // alert(JSON.stringify(appleObj))
              navigate("/regiIntro/apple");
            } else {
              // alert("3")
              try {
                let isOk_login = await apiFn.fnNewSignIn({
                  state: {
                    id: isOk["id"],
                    pw: data["user"],
                    // pw : info["id"]
                    // pw : regiData["pw"]
                  },
                });
                //alert(JSON.stringify(isOk_login))
                setUserInfo({
                  ...isOk_login,
                });
                if (isOk_login["type"] == 1) {
                  navigate(`/message/student/home`);
                } else {
                  navigate(`/message/teacher/home`);
                }
              } catch (e) {
                console.error(e);
                alert("로그인 실패했습니다.");
                util.removeLocalStorage();
                navigate("/", { replace: true });
              }
            }
          } catch (e) {
            // alert("4")
            // alert(JSON.stringify(e))
          }
        });
      } else {
      }
    }
    // 웹으로 들어갔을 때, isDev=true 일때, 관리자 페이지
    // 가 아니라 hiqsum deeplink 시도, 안 열면 설치 페이지 유도
    else if (!constants.isDev) {
      const redirectApp = () => {
        console.log(useLoca.pathname);
        location.href = process.env.REACT_APP_DEEP_LINK + useLoca.pathname;
        checkInstallApp();
      };

      function checkInstallApp() {
        function clearTimers() {
          clearInterval(check);
          clearTimeout(timer);
        }

        function isHideWeb() {
          if (documentAny.webkitHidden || document.hidden) {
            clearTimers();
          }
        }

        const check = setInterval(isHideWeb, 2000);

        const timer = setTimeout(function () {
          redirectStore();
        }, 5000);
      }

      const redirectStore = () => {
        const ua = navigator.userAgent.toLowerCase();

        // 모바일 장치인지 확인
        if (ua.includes("mobile") || ua.includes("android") || ua.includes("iphone")) {
          if (window.confirm("스토어로 이동하시겠습니까?")) {
            location.href =
              ua.indexOf("android") > -1
                ? process.env.REACT_APP_PLAY_STORE
                : process.env.REACT_APP_APP_STORE;
          }
        } else {
          // PC 브라우저인 경우 실행할 코드
        }
      };

      redirectApp();
    }

    setUserInfo((userInfo = {}));
    util.c_log("*** APP.js ***");

    getStorage();

    store.set("stack", []);

    // alert(window.navigator.userAgent.split("(")[1])
  }, []);

  useEffect(() => {
    console.log("## clipData : ", clipData);
  }, [clipData]);

  //   useEffect(()=>{
  //       console.log("APP JSX isDetail => ", isDetail);
  //   },[isDetail])

  //   useEffect(()=>{
  //       console.log("APP JSX isSR_detail => ",isSR_detail);
  //   },[isSR_detail])

  //   useEffect(()=>{
  // 0913 임시 주석 (message List call 관련)
  // setIsLoading(true);
  // if(window.location.pathname.includes("/message")){
  //   apiPro_counts();
  // }

  //   },[navigate])

  useEffect(() => {
    try {
      // console.log(" useLoca PathName => ", useLoca["pathname"]);
      if (useLoca["pathname"].includes("/message")) {
        if (useLoca["pathname"].includes("/mark")) {
          setIsLoading(false);
        }
      }
      windowAny.ReactNativeWebView.postMessage(useLoca["pathname"]);
      windowAny.ReactNativeWebView.postMessage(`id:${userInfo["id"]}&${useLoca["pathname"]}`);
    } catch (e) {
      // // console.log("@ ",e)
    }
  }, [useLoca, userInfo]);

  useEffect(() => {
    if (!ws.current) {
      connectWs();
    }
  }, [userInfo]);

  //   useEffect(()=>{
  // // console.log("c_regiData = >",c_regiData)
  //   },[c_regiData])

  //   useEffect(()=>{
  // // console.log("l_regiData = >",l_regiData)
  //   },[l_regiData])

  //   useEffect(()=>{
  // // console.log("l_time = >",l_time)
  //   },[l_time])

  //   useEffect(()=>{
  // // console.log("hiddenNav => ",hiddenNav)
  //   },[hiddenNav])

  // useEffect(()=>{
  //    console.log("isLoading : ",isLoading)
  // },[isLoading])

  //   useEffect(()=>{
  //     console.log("lectureId app => ",lectureId);
  //   },[lectureId])

  //   useEffect(()=>{
  //       console.log("SCROLLLLLLLLLL KEY : ", scrollKey)
  //   },[scrollKey])

  // useEffect(()=>{
  //     console.log("APP JSX ", qnaData)
  // },[qnaData])

  const [isPremium, setIsPremium] = useState(false);

  const getPremium = async () => {
    if (userInfo?.id) {
      const isPremium = await (
        await fetch(`${constants.apiUrl}/user/${userInfo.id}/premium`)
      ).json();
      setIsPremium(isPremium);
    }
  };
  useEffect(() => {
    // util.c_log("*** Frame.jsx ***");
    if (!userInfo) {
      navigate("/");
    } else {
      getPremium();
    }
  }, [userInfo]);

  return (
    <PaymentContext.Provider
      value={{
        academyId: academyId,
        setAcademyId: setAcademyId,
        defaultAcademyId: defaultAcademyId,
        setDefaultAcademyId: setDefaultAcademyId,
        isPaymentModalOpen: isPaymentModalOpen,
        setPaymentModalOpen: setPaymentModalOpen,
        userInfo,
        inventories: ticketInventories,
        setInventories: setTicketInventories,
        tickets,
        setTickets,
        updatedAt,
        setForceUpdate,
        paymentOpen: paymentOpen,
        setPaymentOpen: setPaymentOpen,
        pickResult,
        setPickResult,
        requestPickTicket,
        isSelectTeacherModalOpen,
        setIsSelectTeacherModalOpen,
        selectedLectureId,
        setSelectedLectureId,
        selectedTeacherId,
        setSelectedTeacherId,
      }}
    >
      <CommonContext.Provider
        value={{
          qnaData,
          setQnaData,
          fileObj,
          setFileObj,
          isFull,
          setIsFull,
          bookInQuestionList,
          setBookInQuestionList,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // width : isFull && window.screen.width,
            // height : isFull && window.screen.height,
            // overflow: isFull && "hidden",
          }}
          className={"mother_"}
        >
          {constants.isInspect ? (
            <UiLoading text="HiQ App 점검중 입니다." isText={false} />
          ) : (
            <SentryRoutes>
              <Route
                path="/"
                element={<Before setUserInfo={setUserInfo} setRegiData={setRegiData} />}
              />
              <Route
                path="/:key"
                element={<Before setUserInfo={setUserInfo} setRegiData={setRegiData} />}
              />
              <Route
                path="/regiChk/:who"
                element={
                  <Register_chk
                    regiData={regiData}
                    setRegiData={setRegiData}
                    setUserInfo={setUserInfo}
                  />
                }
              />
              <Route path="/regiIntro/:type" element={<Register_Intro />} />
              <Route
                path="/regiOne/:who/:type"
                element={<Register_One setRegiData={setRegiData} />}
              />
              <Route
                path="/regiTwo/:who"
                element={<Register_Two regiData={regiData} setRegiData={setRegiData} />}
              />
              <Route
                path="/regiThree/:who"
                element={
                  <Register_Three
                    regiData={regiData}
                    setRegiData={setRegiData}
                    setUserInfo={setUserInfo}
                  />
                }
              />
              <Route
                path="/regiFour/:who"
                element={
                  <Register_Four
                    regiData={regiData}
                    setRegiData={setRegiData}
                    setFileObj={setFileObj}
                    userInfo={userInfo}
                  />
                }
              />
              <Route path="/regiFive/:who/:type" element={<Register_Five userInfo={userInfo} />} />
              <Route
                path="/regiAfter/:who"
                element={
                  <Register_After
                    userInfo={userInfo}
                    t_classData={t_classData}
                    setT_classData={setT_classData}
                    regiData={regiData}
                    setRegiData={setRegiData}
                  />
                }
              />
              <Route
                path="/people/:who/:where"
                element={
                  <People_Main
                    isPremium={isPremium}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    setIsLoading={setIsLoading}
                    setQnaData={setQnaData}
                    setHiddenUrl={setHiddenUrl}
                    setFileObj={setFileObj}
                  />
                }
              />
              <Route path="/lecturelist/:who/:id" element={<Lecture_list userInfo={userInfo} />} />
              <Route
                path="/academy/:who/:where"
                element={
                  <Academy
                    isPremium={isPremium}
                    hiddenNav={hiddenNav}
                    setHiddenUrl={setHiddenUrl}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    s_bookList={s_bookList}
                    setS_BookList={setS_BookList}
                    userInfo={userInfo}
                    setCreateObj={setCreateObj}
                  />
                }
              />
              <Route
                path="/academyall/:who/:id/:where/:cid"
                element={<Academy_book_folder setS_BookList={setS_BookList} userInfo={userInfo} />}
              />
              <Route
                path="/academybook/:who/:id/:where"
                element={<Academy_book_List setS_BookList={setS_BookList} userInfo={userInfo} />}
              />
              <Route
                path="/academyfolder/:who"
                element={
                  <Academy_folder_list
                    userInfo={userInfo}
                    s_bookList={s_bookList}
                    setS_BookList={setS_BookList}
                  />
                }
              />
              <Route path="/academyexist/:who" element={<Academy_exist userInfo={userInfo} />} />
              <Route
                path="/academyregi/:who"
                element={<Academy_regi_one c_regiData={c_regiData} setC_regiData={setC_regiData} />}
              />
              <Route
                path="/academyregitwo/:who/:form"
                element={<Academy_regi_two c_regiData={c_regiData} setC_regiData={setC_regiData} />}
              />
              <Route
                path="/academyregitwoadd/:who"
                element={
                  <Academy_regi_two_add c_regiData={c_regiData} setC_regiData={setC_regiData} />
                }
              />
              <Route
                path="/academyregithree/:who"
                element={
                  <Academy_regi_three
                    userInfo={userInfo}
                    c_regiData={c_regiData}
                    setC_regiData={setC_regiData}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                  />
                }
              />
              <Route
                path="/academybookregi/:who/:form"
                element={
                  <Academy_book_regi
                    userInfo={userInfo}
                    c_regiData={c_regiData}
                    setC_regiData={setC_regiData}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                  />
                }
              />
              <Route
                path="/column/:who/:where"
                element={
                  <Column
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    setBoardObj={setBoardObj}
                    createObj={createObj}
                    setCreateObj={setCreateObj}
                    hiddenNav={hiddenNav}
                    setHiddenNav={setHiddenNav}
                  />
                }
              />
              <Route
                path="/columndetail/:who/:id/:type"
                element={
                  <Index
                    qnaData={qnaData}
                    setQnaData={setQnaData}
                    setHiddenNav={setHiddenNav}
                    fileObj={fileObj}
                    setFileObj={setFileObj}
                    userInfo={userInfo}
                  />
                }
              />
              <Route path="/classdetail/:who/:id/:type" element={<ClassDetail />} />
              <Route path="/picklist/:who/:id" element={<Column_pick_list userInfo={userInfo} />} />
              <Route
                path="/columnclassify/:who/:id"
                element={
                  <Column_pick_classify
                    userInfo={userInfo}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                  />
                }
              />
              {/* 메세지  */}
              <Route
                path="/talk/:who/:where/:qid/:sid/:tid"
                element={
                  <Message_talk
                    clipData={clipData}
                    setClipData={setClipData}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    isSide={isSide}
                    setIsSide={setIsSide}
                    setModiValue={setModiValue}
                    messageList={messageList}
                    senderInfo={senderInfo}
                    setSenderInfo={setSenderInfo}
                    videoInfo={videoInfo}
                    setVideoInfo={setVideoInfo}
                    oriQnaRoom={oriQnaRoom}
                    setOriQnaRoom={setOriQnaRoom}
                    userInfo={userInfo}
                    setQnaData_app={setQnaData}
                    setFileObj={setFileObj}
                    hiddenUrl={hiddenUrl}
                    setHiddenUrl={setHiddenUrl}
                    youtube_url={youtube_url}
                    setYoutube_url={setYoutube_url}
                    solutionList={solutionList}
                    setSolutionList={setSolutionList}
                    websocket={ws?.current}
                  />
                }
              />
              <Route
                path="/invite/:who/:where/:qid/:sid/:tid"
                element={<Message_invite userInfo={userInfo} />}
              />
              <Route
                path="/review/:who/:where/:qid/:sid/:tid"
                element={
                  <Message_talk_review
                    userInfo={userInfo}
                    setQnaData={setQnaData}
                    setFileObj={setFileObj}
                    setHiddenUrl={setHiddenUrl}
                  />
                }
              />
              <Route
                path="/messageteacher/:who/:bid/:qid/:subs/:tid"
                element={<Message_teacher userInfo={userInfo} />}
              />
              <Route path="/list/:who/:qid/:sid/:tid" element={<Message_book_list />} />
              <Route
                path="/message/:who/:where"
                element={
                  <Message
                    hiddenUrl={undefined}
                    isPremium={isPremium}
                    tcnt={tcnt}
                    setAddSearch={setAddSearch}
                    isSR_detail={isSR_detail}
                    setIsSR_detail={setIsSR_detail}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    t_page={t_page}
                    setT_page={setT_page}
                    listStat={listStat}
                    setListStat={setListStat}
                    lectureId={lectureId}
                    setLectureId={setLectureId}
                    messageList={messageList}
                    setMessageList={setMessageList}
                    message_page={message_page}
                    setMessage_page={setMessage_page}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    setQnaData={setQnaData}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    createObj={createObj}
                    setCreateObj={setCreateObj}
                    apiPro_counts={apiPro_counts}
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    setHiddenUrl={setHiddenUrl}
                  />
                }
              />
              <Route
                path="/messagemodi/:who/:qid/"
                element={<Message_modi modiValue={modiValue} userInfo={userInfo} />}
              />
              <Route
                path="/messageMark/:who/:id/:form"
                element={
                  <Message_Mark_List
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    userInfo={userInfo}
                    setCreateObj={setCreateObj}
                    setHiddenUrl={setHiddenUrl}
                  />
                }
              />
              <Route path="/oxNote" element={<MessageMark />} />
              <Route path="/oxNote/:id" element={<MessageMarkList />} />
              <Route
                path="/messageFolder/:who/:qid/:form"
                element={<Message_Folder userInfo={userInfo} />}
              />
              <Route
                path="/profile/:who/:id/:where"
                element={
                  <Profile
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    setHiddenUrl={setHiddenUrl}
                    setHiddenNav={setHiddenNav}
                    hiddenNav={hiddenNav}
                    isPremium={isPremium}
                  />
                }
              />
              <Route
                path="/profileregi/:who/:id/:form"
                element={<Profile_regi userInfo={userInfo} />}
              />
              <Route
                path="/lectureregitwo/:who/:form/:id"
                element={
                  <Profile_lecture_regi_two
                    userInfo={userInfo}
                    l_regiData={l_regiData}
                    setL_regiData={setL_regiData}
                    l_time={l_time}
                    setL_time={setL_time}
                  />
                }
              />
              <Route
                path="/lectureregithree/:who/:id"
                element={
                  <Profile_lecture_regi_three
                    l_regiData={l_regiData}
                    setL_regiData={setL_regiData}
                    l_time={l_time}
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                  />
                }
              />
              <Route
                path="/lecture/detail/:who/:id/:where"
                element={
                  <Profile_lecture_detail
                    setUserInfo={setUserInfo}
                    explainData={explainData}
                    setExplainData={setExplainData}
                    explainPage={explainPage}
                    setExplainPage={setExplainPage}
                    fn={fn}
                    hiddenUrl={hiddenUrl}
                    userInfo={userInfo}
                    setHiddenNav={setHiddenNav}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    hiddenNav={hiddenNav}
                    setQnaData={setQnaData}
                    setHiddenUrl={setHiddenUrl}
                    setAddSearch={setAddSearch}
                    isPremium={isPremium}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                }
              />
              <Route
                path="/lecturemodi/:who/:id/:form"
                element={<Lecture_modi userInfo={userInfo} />}
              />
              <Route
                path="/lecturemain/:who/:where"
                element={<Lecture_Main userInfo={userInfo} isPremium={isPremium} />}
              />
              <Route path="/lecture" element={<LectureVideo />} />
              <Route path="/lecture/search" element={<LectureVideoSearch />} />
              <Route
                path="/bookmain/:who/:where"
                element={
                  <Book_Main
                    isPremium={isPremium}
                    fileObj={fileObj}
                    setFileObj={setFileObj}
                    userInfo={userInfo}
                  />
                }
              />
              <Route
                path="/bookfolder/:who/:id/:form"
                element={<Book_folder userInfo={userInfo} />}
              />
              <Route
                path="/book/:who/:id/:cid/:where"
                element={
                  <Book
                    isFull={isFull}
                    setIsFull={setIsFull}
                    explainData={explainData}
                    setExplainData={setExplainData}
                    explainPage={explainPage}
                    setExplainPage={setExplainPage}
                    del_bookdId={del_bookdId}
                    setDel_bookId={setDel_bookId}
                    setDel_sorting={setDel_sorting}
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    qnaData={qnaData}
                    setQnaData={setQnaData}
                    setHiddenUrl={setHiddenUrl}
                    setHiddenNav={setHiddenNav}
                    hiddenNav={hiddenNav}
                    setAddSearch={setAddSearch}
                    isPremium={isPremium}
                  />
                }
              />
              <Route path="/study-book" element={<StudyBook />} />
              <Route path="/study-book/folder/:folderId" element={<StudyBookFolder />} />
              <Route path="/study-book/:bookId" element={<StudyBookDetail />} />
              <Route
                path="/bookmodi/:who/:id/:form/:cid"
                element={<Book_modi userInfo={userInfo} />}
              />
              <Route
                path="/bookremove/:who/:id/:cid/:where"
                element={
                  <Book_remove
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    setQnaData={setQnaData}
                  />
                }
              />
              <Route
                path="/booksubsset/:who/:bid"
                element={
                  <Book_subs_set fileObj={fileObj} setFileObj={setFileObj} userInfo={userInfo} />
                }
              />
              <Route
                path="/booksharelist/:who/:id/:form"
                element={<Book_share_list userInfo={userInfo} />}
              />
              <Route
                path="/explainmodel/:who/:id/:form/:type"
                element={
                  <QNA
                    explainPage={explainPage}
                    setExplainPage={setExplainPage}
                    qnaData={explainData}
                    setQnaData={setExplainData}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    userInfo={userInfo}
                    setHiddenUrl={setHiddenUrl}
                    isPremium={isPremium}
                  />
                  // <ExplainModel
                  // explainPage={explainPage}
                  // setExplainPage={setExplainPage}
                  // qnaData={explainData}
                  // setQnaData={setExplainData}
                  // scrollKey={scrollKey}
                  // setScrolllKey={setScrolllKey}
                  // userInfo={userInfo}
                  // setHiddenUrl={setHiddenUrl}
                  // />
                }
              />
              <Route
                path="/mobumregi/:who/:id/:form/:type"
                element={
                  <QnaRegi userInfo={userInfo} setHiddenUrl={setHiddenUrl} />
                  //   <MobumRegi
                  // userInfo={userInfo}
                  // setHiddenUrl={setHiddenUrl}
                  //   />
                }
              />
              <Route path="/reply/:who/:id/:where" element={<Reply userInfo={userInfo} />} />
              <Route path="/warn/:who/:id/:where" element={<Warn userInfo={userInfo} />} />
              <Route
                path="/notice/:who/:id/:type"
                element={<UiNoticeDetail userInfo={userInfo} isPremium={isPremium} />}
              />
              <Route
                path="/myinfo/:who/:id"
                element={
                  <MyInfo_Profile
                    hiddenUrl={hiddenUrl}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    userInfo={userInfo}
                    hiddenNav={hiddenNav}
                    setHiddenUrl={setHiddenUrl}
                    setHiddenNav={setHiddenNav}
                    setQnaData={setQnaData}
                    isPremium={isPremium}
                  />
                }
              />
              <Route path="/myinforeview/:who" element={<MyInfo_review userInfo={userInfo} />} />
              <Route
                path="/myinfo/ticketlog/:who/:id"
                element={<MyInfo_ticketlog userInfo={userInfo} />}
              />
              <Route
                path="/myinfo/orders/:id"
                element={<MyInfo_orderlog userInfo={userInfo} isCancel={undefined} />}
              />
              <Route
                path="/myinfo/order/:id"
                element={<MyInfo_orderdetail userInfo={userInfo} />}
              />
              {/* <Route
                                path="/myinfo/order-cancels/:id"
                                element={
                                    <MyInfo_orderlog
                                        userInfo={userInfo}
                                    />
                                }
                            /> */}
              <Route path="/myinfop/:who" element={<MyInfo_PW userInfo={userInfo} />} />
              <Route
                path="/myinfo"
                element={
                  <Myinfo_Main
                    setIsPremium={setIsPremium}
                    isPremium={isPremium}
                    userInfo={userInfo}
                  />
                }
              />
              <Route
                path="/myinfo/question"
                element={<Myinfo_Question userInfo={userInfo} isPremium={isPremium} />}
              />
              <Route path="/myinfom/:who/:form" element={<MyInfo_infoModi userInfo={userInfo} />} />
              <Route
                path="/searchresult/:who/:form/:type"
                element={
                  <Search
                    solutionList={solutionList}
                    setSolutionList={setSolutionList}
                    isSR_detail={isSR_detail}
                    setIsSR_detail={setIsSR_detail}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    addSearch={addSearch}
                    qnaData={qnaData}
                    setQnaData={setQnaData}
                    userInfo={userInfo}
                    fileObj={fileObj}
                    setFileobj={setFileObj}
                    hiddenUrl={hiddenUrl}
                    setHiddenUrl={setHiddenUrl}
                  />
                  //   <Search_result
                  // solutionList={solutionList}
                  // setSolutionList={setSolutionList}
                  // isSR_detail={isSR_detail}
                  // setIsSR_detail={setIsSR_detail}
                  // scrollKey={scrollKey}
                  // setScrolllKey={setScrolllKey}
                  // addSearch={addSearch}
                  // setAddSearch={setAddSearch}
                  // qnaData={qnaData}
                  // setQnaData={setQnaData}
                  // userInfo={userInfo}
                  // fileObj={fileObj}
                  // setFileobj={setFileObj}
                  // hiddenUrl={hiddenUrl}
                  // setHiddenUrl={setHiddenUrl}
                  //   />
                }
              />
              <Route
                path="/postsearchresult/:who/:qid"
                element={
                  <Post_Search
                    ori_data={ori_data}
                    setOri_data={setOri_data}
                    isSR_detail={isSR_detail}
                    setIsSR_detail={setIsSR_detail}
                    solutionList={solutionList}
                    setSolutionList={setSolutionList}
                    setScrolllKey={setScrolllKey}
                    qnaData={qnaData}
                    setHiddenUrl={setHiddenUrl}
                    oriQnaRoom={oriQnaRoom}
                  />
                }
              />
              <Route
                path="/invite/:who/:form/:type/:key"
                element={
                  <Invite fn={fn} userInfo={userInfo} id_set={id_set} setId_set={setId_set} />
                }
              />
              <Route
                path="/write/:who/:type/:id/:where/:modi"
                element={
                  <Board_write
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    boardObj={boardObj}
                    setBoardObj={setBoardObj}
                    setHiddenUrl={setHiddenUrl}
                    tempTitleData={tempTitleData}
                    setTempTitleData={setTempTitleData}
                    cat={cat}
                    setCat={setCat}
                  />
                }
              />
              <Route
                path="/video/:who/:type"
                element={
                  <Video
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    boardObj={boardObj}
                    setBoardObj={setBoardObj}
                    hiddenUrl={hiddenUrl}
                  />
                }
              />
              <Route
                path="/crop/:who/:type"
                element={
                  <Apps
                    setIsCrop={false}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    qnaData={qnaData}
                    addSearch={addSearch}
                    // userInfo={userInfo}
                    // id_set={id_set}
                    // setId_set={setId_set}
                  />
                }
              />
              <Route
                path="/view/:who/:qid/:type/:mo/:tid"
                element={
                  <ViewQna
                    setHiddenUrl={setHiddenUrl}
                    senderInfo={senderInfo}
                    setFileObj={setFileObj}
                    setSenderInfo={setSenderInfo}
                    fileObj={fileObj}
                    userInfo={userInfo}
                    youtube_url={youtube_url}
                    videoInfo={videoInfo}
                    qnaData_app={qnaData}
                  />
                }
              />
              <Route path="/create/:who" element={<UiCreate createObj={createObj} />} />
              <Route
                path="/youtube/:who/:where"
                element={
                  <Youtube
                    isPremium={isPremium}
                    userInfo={userInfo}
                    setScrolllKey={setScrolllKey}
                    scrollKey={scrollKey}
                  />
                }
              />
              <Route
                path="/tutorial"
                element={
                  <Tutorial
                    onClose={() => {
                      localStorage.setItem("showTutorial", JSON.stringify(false));
                      if (stack.length > 0) navigate(-1);
                      else navigate("/", { replace: true });
                    }}
                  />
                }
              />
              <Route path="/class/video/list" element={<LectureVideoList />} />
              <Route path="/class/:cid/video/regist" element={<LectureRegist />} />
              <Route path="/class/:cid/video/update/:boardId" element={<LectureUpdate />} />
              <Route path={"/pdfView/:bookId"} element={<PdfView />} />
              <Route
                path="/deactivate-account"
                element={<DeactivateAccount setUserInfo={setUserInfo} />}
              />
              <Route path={"/contactUs"} element={<ContactUs />} />
              <Route path="/order" element={<Order />} />
              <Route path="/payment/callback" element={<PaymentMobileRedirect />} />
              <Route
                path="/search/question/image"
                element={
                  <QuestionImageSearch
                    ori_data={ori_data}
                    setHiddenUrl={setHiddenUrl}
                    setIsSR_detail={setIsSR_detail}
                    setScrolllKey={setScrolllKey}
                  />
                }
              />
              <Route
                path={"/search/question/book/:bookId/:searchType"}
                element={
                  <QuestionBookSearch
                    ori_data={ori_data}
                    setHiddenUrl={setHiddenUrl}
                    setIsSR_detail={setIsSR_detail}
                  />
                }
              />
              <Route
                path="/searchresult/student/student/p/:teacherId/:academyId"
                element={<SelectLecture setFileObj={setFileObj} setQnaData={setQnaData} />}
              />
              <Route
                path="/searchresult/student/student/p"
                element={<SelectLecture setFileObj={setFileObj} setQnaData={setQnaData} />}
              />
              <Route path="/book/:bookId" element={<BookDetailPage />} />
              <Route path="/bookDemo" element={<PDFdemo />} />
            </SentryRoutes>
          )}
          {isBlock && <UiLoading isTransparent={true} isText={false} isCircle={false} text="" />}
          {/* <CommonUploadInfo /> */}
        </div>
      </CommonContext.Provider>
    </PaymentContext.Provider>
  );
}

export default App;
