import { CustomToastContent } from "providers/toast/content";
import type { ToastCloseType } from "providers/toast/content/types";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

type ToastType = {
  showInfo: (message: string, title?: string, close?: boolean | ToastCloseType) => void;
  showSuccess: (message: string, title?: string, close?: boolean | ToastCloseType) => void;
  showError: (message: string, title?: string, close?: boolean | ToastCloseType) => void;
};

export function useToast(): ToastType {
  const showToast = useCallback(
    (
      type: "info" | "success" | "error",
      message: string,
      title?: string,
      close?: boolean | ToastCloseType
    ) => {
      toast[type](<CustomToastContent title={title} message={message} close={close} />);
    },
    []
  );

  const showInfo = useCallback(
    (message: string, title?: string, close?: boolean | ToastCloseType) =>
      showToast("info", message, title, close),
    []
  );

  const showSuccess = useCallback(
    (message: string, title?: string, close?: boolean | ToastCloseType) =>
      showToast("success", message, title, close),
    []
  );

  const showError = useCallback(
    (message: string, title?: string, close?: boolean | ToastCloseType) =>
      showToast("error", message, title, close),
    []
  );

  return { showInfo, showSuccess, showError };
}
