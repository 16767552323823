import React, { useMemo } from "react";

import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import { Close } from "@material-ui/icons";
import { PdfType, usePdfList } from "../../../../api/pdfView";
import ImgItem from "./ImgItem";
import { Modal } from "@mui/material";
import type { SwiperClass } from "swiper/swiper-react";

const PdfAllViewModal = ({
  open,
  onClose,
  currentSlide,
  swiper,
  isQuestion,
}: {
  open: boolean;
  onClose: () => void;
  currentSlide: number;
  swiper: SwiperClass;
  isQuestion: boolean;
}) => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { data } = usePdfList({
    bookId: params.bookId,
    isAnswer: isQuestion ? PdfType.question : PdfType.answer,
  });
  const pdfImgList = useMemo(() => {
    return data?.qnaList;
  }, [data]);

  return (
    <Modal open={open} onClose={onClose}>
      <PdfViewAllItemsContainer>
        <PdfViewAllItemHeader>
          <div>
            {currentSlide + 1}/{pdfImgList.length}
          </div>
          <HeaderRightBox>
            <Button onClick={() => onClose()}>
              <Close />
            </Button>
          </HeaderRightBox>
        </PdfViewAllItemHeader>
        <PdfViewAllItemContent>
          {pdfImgList.length > 0 &&
            pdfImgList.map((item, index) => (
              <ImgItem
                item={item}
                key={index}
                index={index}
                className={currentSlide === index ? "active" : ""}
                onClick={() => {
                  swiper.slideTo(index);
                  onClose();
                }}
              />
            ))}
        </PdfViewAllItemContent>
      </PdfViewAllItemsContainer>
    </Modal>
  );
};

export default PdfAllViewModal;

const PdfViewAllItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #f1f1f1;
  z-index: 3;
  padding: 16px;
  box-sizing: border-box;
`;
const PdfViewAllItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderRightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
const Button = styled.button`
  background: transparent;
  border: 0;
  font-size: 16px;
`;
const PdfViewAllItemContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 10px;
  overflow: scroll;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  padding: 4px 4px;
`;
