import type { IcDefatultProps } from "../types";
import React from "react";

export function IcArrowLeft({
  width = 16,
  height = 16,
  color = "#434343",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33335 3.33317L4.66669 7.99984L9.33335 12.6665"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
