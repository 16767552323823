import type { IcDefatultProps } from "../types";
import React from "react";

export function IcMenu({
  width = 24,
  height = 25,
  color = "#262626",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3.33203" y="8" width="18" height="1.5" rx="0.75" fill={color} />
      <rect x="3.33203" y="16" width="18" height="1.5" rx="0.75" fill={color} />
    </svg>
  );
}
