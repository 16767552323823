import type { AnswerDTO } from "../book/dtos";
import { EndPoints } from "../constants";
import type { RequsetSearchAnswer } from "./dtos";
import { requestPost } from "../common";

export async function searchAnswer(req: RequsetSearchAnswer): Promise<AnswerDTO[]> {
  const res = requestPost<AnswerDTO[], RequsetSearchAnswer>(EndPoints.SEARCH_ANSWER, req);

  return res;
}
