import { localForageEffect } from "helpers/recoil";
import { atom } from "recoil";
import { AtomKeys } from "../constants";
import type { searchHistoryType } from "./types";

export const searchHistoryAtom = atom<searchHistoryType[]>({
  key: AtomKeys.SEARCH_HISTORY,
  default: [],
  effects: [localForageEffect(AtomKeys.SEARCH_HISTORY)],
});
