import { Button, Stack, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useCallback, useState } from "react";
import { IcMore } from "../../atoms/icons/more";
import { StyledMenu } from "./styles";
import type { MoreButtonProps } from "./types";

export function MoreButton({ items }: MoreButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleItemClick = useCallback((onClick?: () => void) => {
    setAnchorEl(null);
    if (onClick) {
      onClick();
    }
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <Button
        id="more-button"
        aria-controls={open ? "more-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleButtonClick}
        sx={{
          backgroundColor: "#8b8b8b",
          border: "none",
          borderRadius: "5px",
          width: "32px",
          minWidth: "32px",
          height: "32px",
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ":hover": {
            backgroundColor: "#8b8b8b",
          },
        }}
      >
        <IcMore />
      </Button>
      <StyledMenu
        id="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        MenuListProps={{
          "aria-labelledby": "more-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {items.map((item, index) => {
          return (
            <MenuItem
              key={item.title}
              onClick={() => handleItemClick(item.onClick)}
              divider={index + 1 !== items.length}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Typography fontSize="14px">{item.title}</Typography>
                {item.icon}
              </Stack>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
