import type { IcDefatultProps } from "../types";
import React from "react";

export function IcExitFullScreen({
  width = 24,
  height = 24,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49063 20.0094L11.1259 13.3742M11.1259 13.3742L11.4576 17.0236M11.1259 13.3742L7.47648 13.0424"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5094 3.99057L13.8741 10.6258M13.8741 10.6258L13.5424 6.97642M13.8741 10.6258L17.5235 10.9576"
        stroke={color}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
