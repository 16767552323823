import { styled } from "@mui/material";

export const StyledInput = styled("input")(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.primary.dark,
  borderRadius: "10px",
  textAlign: "center",
  width: "56px",
  height: "28px",
  fontSize: "12px",
  border: 0,
}));
