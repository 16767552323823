export const neutralPalette = {
  40: "#e4e4e4",
  50: "#8c8c8c", // main
  70: "#262626", // dark
};

export const backgroudPalette = {
  default: "#fafafa",
  paper: "#e4e4e4",
};
