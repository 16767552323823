import { realIndexAtom } from "application/recoils/swiper/atoms";
import { answerViewAtom, fullSreenAtom, sideMenuAtom } from "application/recoils/ui/atoms";
import { Appbar } from "components/molecules/appbar";
import { QnAView } from "components/templates/qnaView";
import React, { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import type { BookDetailProps } from "./types";
import SideMenu from "../../../pages/StudyBookDetail/components/SideMenu";
import { useStudyBookInfo } from "../../../api/studyBook";
import { useParams } from "react-router";
import { EmptyView } from "../../../components/templates/emptyView";

export default function BookDetail({ data }: BookDetailProps): JSX.Element {
  const params = useParams();
  const isFullScreen = useRecoilValue(fullSreenAtom);
  const setRealIndex = useSetRecoilState(realIndexAtom);
  const setIsAnswerView = useSetRecoilState(answerViewAtom);
  const setIsSideMenu = useSetRecoilState(sideMenuAtom);
  const isSideMunu = useRecoilValue(sideMenuAtom);
  const bookData = useStudyBookInfo(params.bookId);

  const onBackButtonClick = useCallback(() => {
    setRealIndex(0);
    setIsAnswerView(false);
  }, []);

  const onMenuButtonClick = useCallback(() => {
    setIsSideMenu((prev) => !prev);
  }, []);

  const bookName = useMemo(() => {
    return bookData?.data?.getBook?.book?.name;
  }, [bookData]);

  const QuestionList = useMemo(() => {
    if (!data || data.length === 0) {
      return <EmptyView />;
    }
    if (data) {
      return <QnAView data={data} />;
    }
  }, []);

  return (
    <>
      {!isFullScreen && (
        <Appbar>
          <Appbar.BackButton onClick={onBackButtonClick} />
          <Appbar.Title title={bookName} />
          <Appbar.MenuButton onClick={onMenuButtonClick} />
        </Appbar>
      )}
      {isSideMunu && <SideMenu />}
      {QuestionList}
    </>
  );
}
