import { answerViewAtom } from "application/recoils/ui/atoms";
import { useTheme } from "@mui/material";
import { useRecoilValue } from "recoil";
import type { BaseLayoutProps } from "./types";
import React from "react";

// NOTE: baselayout의 padding을 벗어난 layout
export default function VariantLayout({ children }: BaseLayoutProps): JSX.Element {
  const isAnswerView = useRecoilValue(answerViewAtom);
  const theme = useTheme();

  return (
    <div
      // NOTE: QuestionSwiper만 노출됐을 때는 width를 꽉 채워야 하기에 margin을 음수값을 줘서 baselayout에서 벗어나게 함
      style={{
        height: "100vh",
        ...(!isAnswerView && {
          height: "100%",
          margin: "0 -24px",
          [theme.breakpoints.down("md")]: {
            margin: "0 -20px",
          },
        }),
      }}
    >
      {children}
    </div>
  );
}
