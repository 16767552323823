import { actionButtonsAtom, fullSreenAtom } from "application/recoils/ui/atoms";
import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import { IcFullScreen } from "../../atoms/icons/fullScreen";

export function FullScreenButton(): JSX.Element {
  const setIsFullScreen = useSetRecoilState(fullSreenAtom);
  const setShowActionsButton = useSetRecoilState(actionButtonsAtom);

  const onFullSreenClick = useCallback(() => {
    setIsFullScreen(true);
    setShowActionsButton((prev) => ({
      question: prev.question === true ? false : prev.question,
      answer: prev.answer === true ? false : prev.answer,
    }));
  }, []);

  return (
    <button
      type="button"
      aria-label="full-screen"
      style={{
        backgroundColor: "#8b8b8b",
        border: "none",
        borderRadius: "5px",
        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onFullSreenClick}
    >
      <IcFullScreen />
    </button>
  );
}
