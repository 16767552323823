import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ApolloClientProvider from "./ApolloClientProvider";
import ConfirmProvider from "./ConfirmProvider";
import ErrorBoundary from "./ErrorBoundary";
import ReactQueryClientProvider from "./ReactQueryClientProvider";
import ReduxProvider from "./ReduxProvider";
import RootSuspense from "./RootSuspense";
import RootThemeProvider from "./RootThemeProvider";
import HotToastProvider from "./ToastProvider";
import { RecoilRoot } from "recoil";
import { ToastProvider } from "./toast";

const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <RecoilRoot>
        <ToastProvider>
          <HelmetProvider>
            <BrowserRouter>
              <ErrorBoundary>
                <ReactQueryClientProvider>
                  <ApolloClientProvider>
                    <HotToastProvider>
                      <RootSuspense>
                        <RootThemeProvider>
                          <ConfirmProvider>{children}</ConfirmProvider>
                        </RootThemeProvider>
                      </RootSuspense>
                    </HotToastProvider>
                  </ApolloClientProvider>
                </ReactQueryClientProvider>
              </ErrorBoundary>
            </BrowserRouter>
          </HelmetProvider>
        </ToastProvider>
      </RecoilRoot>
    </ReduxProvider>
  );
};

export default Providers;
