import { Stack, useTheme } from "@mui/material";
import { IcExitFullScreen } from "../../../components/atoms/icons/exitFullScreen";
import React, { useCallback } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { actionButtonsAtom, fullSreenAtom } from "../../../application/recoils/ui/atoms";
import PdfAnswerSwitch from "./PdfAnswerSwitch";

const PdfQuestionToolbar = () => {
  const [isFullScreen, setIsFullScreen] = useRecoilState(fullSreenAtom);
  const setShowActionsButton = useSetRecoilState(actionButtonsAtom);
  const theme = useTheme();
  const handleExitFullScreen = useCallback(() => {
    setShowActionsButton((prev) => ({
      question: prev.question === true ? false : prev.question,
      answer: prev.answer === true ? false : prev.answer,
    }));
    setIsFullScreen(false);
  }, []);
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      py="18px"
      px={isFullScreen ? "20px" : 0}
      sx={{
        backgroundColor: isFullScreen ? "primary.dark" : "inherit",
        ...(isFullScreen && {
          [theme.breakpoints.up("lg")]: {
            gap: "100px",
            justifyContent: "center",
          },
        }),
      }}
    >
      <PdfAnswerSwitch />
      {isFullScreen && (
        <button type="button" aria-label="exit-full-screen" onClick={handleExitFullScreen}>
          <IcExitFullScreen />
        </button>
      )}
    </Stack>
  );
};
export default PdfQuestionToolbar;
