import type { IcDefatultProps } from "../types";
import React from "react";

export function IcFullScreen({
  width = 24,
  height = 24,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33335 8.44482L1.33335 3.33371C1.33335 2.22914 2.22878 1.33371 3.33335 1.33371L8.44446 1.33371"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 15.5557V20.6668C22.6667 21.7713 21.7712 22.6668 20.6667 22.6668H15.5555"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5555 1.33371L20.6667 1.33371C21.7712 1.33371 22.6667 2.22914 22.6667 3.33371L22.6667 8.44482"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44446 22.6668L3.33335 22.6668C2.22878 22.6668 1.33335 21.7713 1.33335 20.6668L1.33335 15.5557"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
