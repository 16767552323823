import { EndPoints } from "../constants";
import type { BookDTO, BookResponseData } from "./dtos";
import { requestGet } from "../common";

export async function getQuestionsByBookId(bookId: string): Promise<BookDTO[]> {
  const data = await requestGet<BookResponseData>(
    `${EndPoints.BOOK}/${bookId}/${EndPoints.GET_QUESTIONS_BY_BOOK_ID}`
  );
  return data.qnaList;
}

export async function getAnswersByBookId(bookId: string): Promise<BookDTO[]> {
  const data = await requestGet<BookResponseData>(
    `${EndPoints.BOOK}/${bookId}/${EndPoints.GET_ANSWERS_BY_ID}`
  );
  return data.qnaList;
}
