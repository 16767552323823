import type { IcDefatultProps } from "../types";
import React from "react";

export function IcVerticalAlign({
  width = 24,
  height = 24,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70718 9.70696L12.0001 4.41406L17.293 9.70696L18.7072 8.29274L13.4143 2.99985C12.6332 2.2188 11.3669 2.2188 10.5859 2.99985L5.29297 8.29274L6.70718 9.70696Z"
        fill={color}
      />
      <path
        d="M6.70718 14.2927L12.0001 19.5856L17.293 14.2927L18.7072 15.707L13.4143 20.9998C12.6332 21.7809 11.3669 21.7809 10.5859 20.9998L5.29297 15.707L6.70718 14.2927Z"
        fill={color}
      />
    </svg>
  );
}
