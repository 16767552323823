import { Typography } from "@mui/material";
import React from "react";
import type { AppbarTitleProps } from "./types";

export function AppbarTitle({ title }: AppbarTitleProps): JSX.Element {
  return (
    <Typography
      fontSize="16px"
      fontWeight="bold"
      textAlign="center"
      sx={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: "60%",
        color: "primary.dark",
      }}
    >
      {title}
    </Typography>
  );
}
