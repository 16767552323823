import type { IcDefatultProps } from "../types";
import React from "react";

export function IcRotate({
  width = 16,
  height = 16,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99665 14.6397C7.75237 14.6397 7.5081 14.5922 7.28418 14.4972C7.06026 14.4022 6.84991 14.2665 6.68028 14.0901L1.88299 9.29278C1.70657 9.11636 1.57086 8.9128 1.47587 8.68888C1.38087 8.46496 1.33337 8.22069 1.33337 7.97641C1.33337 7.73214 1.38087 7.48786 1.47587 7.26395C1.57086 7.03324 1.70657 6.82968 1.88299 6.66004L3.40971 5.13333H1.93049C1.65229 5.13333 1.42158 4.90262 1.42158 4.62442C1.42158 4.34622 1.65229 4.11551 1.93049 4.11551H4.63787C4.63787 4.11551 4.65144 4.11551 4.65823 4.11551C4.71929 4.11551 4.78036 4.12909 4.83465 4.15623C4.88893 4.17658 4.94321 4.21051 4.99071 4.25801L5.00428 4.27158C5.05178 4.31908 5.08571 4.37336 5.10606 4.42764C5.12642 4.48193 5.13999 4.543 5.14678 4.60406C5.14678 4.60406 5.14678 4.61764 5.14678 4.62442V7.3318C5.14678 7.61 4.91607 7.84071 4.63787 7.84071C4.35967 7.84071 4.12896 7.61 4.12896 7.3318V5.85258L2.60225 7.3793C2.52082 7.46072 2.45975 7.54893 2.41904 7.6575C2.37833 7.75928 2.35119 7.86785 2.35119 7.9832C2.35119 8.09855 2.37154 8.20712 2.41904 8.3089C2.45975 8.41068 2.52082 8.50568 2.60225 8.5871L7.39953 13.3844C7.48096 13.4658 7.56917 13.5269 7.67773 13.5676C7.88808 13.6558 8.11879 13.6558 8.32913 13.5676C8.43091 13.5269 8.52591 13.4658 8.60055 13.3844L13.3978 8.5871C13.4793 8.50568 13.5403 8.41747 13.581 8.3089C13.6218 8.20712 13.6421 8.09855 13.6421 7.9832C13.6421 7.86785 13.6218 7.75928 13.5743 7.6575C13.5335 7.55572 13.4725 7.46072 13.3911 7.3793L8.60055 2.57523C8.28164 2.25631 7.71845 2.25631 7.39953 2.57523L7.06026 2.9145C6.86348 3.11127 6.53779 3.11127 6.34101 2.9145C6.14423 2.71772 6.14423 2.39202 6.34101 2.19524L6.68028 1.86276C7.38596 1.15708 8.61412 1.15708 9.3198 1.86276L14.1171 6.66004C14.2935 6.83646 14.4292 7.04003 14.5242 7.26395C14.6192 7.49465 14.6667 7.73214 14.6667 7.97641C14.6667 8.22069 14.6192 8.46496 14.5242 8.68888C14.4292 8.91959 14.2935 9.12315 14.1171 9.29278L9.3198 14.0901C9.14338 14.2665 8.93982 14.4022 8.7159 14.4972C8.49198 14.5922 8.24771 14.6397 8.00343 14.6397H7.99665Z"
        fill={color}
      />
    </svg>
  );
}
