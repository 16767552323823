import { useSwiperNavigationStyle } from "application/hooks/libs/swiper";
import { questionIdAtom } from "application/recoils/book/atoms";
import { realIndexAtom, zoomAtom } from "application/recoils/swiper/atoms";
import { actionButtonsAtom, answerViewAtom } from "application/recoils/ui/atoms";
import { Box } from "@mui/material";
import { SwiperContainer } from "components/molecules/swiperContainer";
import React, { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import type SwiperClass from "swiper";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import { ActionButtons } from "../actionButtons";
import { QnASlideItem } from "../qnaSlideItem";
import type { QuestionSwiperProps } from "./types";

export function QuestionSwiper({
  data,
  questionSwiper,
  setSwiper,
  children,
  swiper,
}: QuestionSwiperProps): JSX.Element | null {
  const [realIndex, setRealIndex] = useRecoilState(realIndexAtom);
  const isAnswerView = useRecoilValue(answerViewAtom);
  const [actionButtons, setShowActionsButtons] = useRecoilState(actionButtonsAtom);
  const setQusetionId = useSetRecoilState(questionIdAtom);
  const [isZoomed, setIsZoomed] = useRecoilState(zoomAtom);
  const { changeNavOpacity, changeNavOpacityOnClick } = useSwiperNavigationStyle();

  useEffect(() => {
    if (!questionSwiper) {
      return;
    }
    changeNavOpacityOnClick(questionSwiper);

    // NOTE: full screen on/off시에도 slide 유지
    questionSwiper.slideTo(realIndex, 0);
  }, [questionSwiper, realIndex]);

  const handleSwiperClick = useCallback(
    (swiper: SwiperClass, _e: MouseEvent | TouchEvent | PointerEvent) => {
      setShowActionsButtons((prev) => ({ ...prev, question: !prev.question }));
      changeNavOpacity(swiper, "toggle");
    },
    []
  );

  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      const index = swiper.activeIndex;
      const questionId = data[index].qid;
      setQusetionId(questionId);

      setShowActionsButtons((prev) => ({
        ...prev,
        question: prev.question === true ? false : prev.question,
      }));
      changeNavOpacity(swiper, "invisible");
    },
    [isAnswerView, data]
  );

  const handleOnInit = useCallback(
    (swiper: SwiperClass) => {
      // NOTE: 0번째 questionId로 세팅
      const questionId = data[swiper.activeIndex].qid;
      setQusetionId(questionId);
    },
    [data]
  );

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 1,
      }}
    >
      <SwiperContainer
        className="question-swiper-container"
        slidesPerView={1}
        modules={[Navigation, Pagination, Zoom]}
        navigation
        zoom
        spaceBetween={0}
        speed={0}
        onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
        onRealIndexChange={(swiper: SwiperClass) => setRealIndex(swiper.realIndex)}
        onClick={(swiper: SwiperClass, e: MouseEvent | TouchEvent | PointerEvent) =>
          handleSwiperClick(swiper, e)
        }
        onSlideChange={(swiper: SwiperClass) => handleSlideChange(swiper)}
        onInit={(swiper: SwiperClass) => handleOnInit(swiper)}
        onZoomChange={() => setIsZoomed((prev) => !prev)}
        style={{
          ...(isAnswerView && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }),
          ...(!isAnswerView && isZoomed && { height: "100vh" }),
        }}
      >
        {data.map((d) => {
          return (
            <SwiperSlide key={d.qid} data-qid={d.qid}>
              <QnASlideItem data={d} />
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
      <ActionButtons
        isShow={actionButtons.question}
        data={data[swiper?.activeIndex]}
        swiper={swiper}
        isQuestion={true}
      />
      {children}
    </Box>
  );
}
