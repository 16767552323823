import { Panel, PanelGroup } from "react-resizable-panels";

import { PanelGroupContainerProps } from "./types";
import { PanelResizeHandler } from "../panelResizeHandler";
import React from "react";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

export default function PanelGroupContainer({
  topItem,
  bottomItem,
  defaultSize = 50,
  maxSize = 50,
  minSize = 20,
}: PanelGroupContainerProps): JSX.Element {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <PanelGroup
      autoSaveId="pdfview"
      direction="vertical"
      style={{ ...(!bottomItem && { overflow: undefined }) }}
    >
      <Panel order={1} minSize={minSize} maxSize={maxSize} defaultSize={defaultSize}>
        {topItem}
      </Panel>
      {bottomItem && (
        <>
          <PanelResizeHandler />
          <Panel order={2} defaultSize={defaultSize}>
            {bottomItem}
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}
