import { PdfViewerContainer } from "components/molecules/pdfVewerContainer";
import BaseLayout from "presentation/layouts/base";
import React, { useCallback } from "react";
import PanelGroupContainer from "components/molecules/panelGroupConatiner";
import { MuiProvider } from "../../providers/mui";
import { PdfViewer } from "components/molecules/pdfViewer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { answerViewAtom, fullSreenAtom, sideMenuAtom } from "../../application/recoils/ui/atoms";
import { realIndexAtom } from "../../application/recoils/swiper/atoms";
import { Appbar } from "../../components/molecules/appbar";
import SideMenu from "../StudyBookDetail/components/SideMenu";
import PdfQuestionToolbar from "./component/PdfQuestionToolbar";

const PDFdemo = () => {
  const isFullScreen = useRecoilValue(fullSreenAtom);
  const setRealIndex = useSetRecoilState(realIndexAtom);
  const setIsAnswerView = useSetRecoilState(answerViewAtom);
  const setIsSideMenu = useSetRecoilState(sideMenuAtom);
  const isSideMunu = useRecoilValue(sideMenuAtom);
  const isAnswerView = useRecoilValue(answerViewAtom);

  const onBackButtonClick = useCallback(() => {
    setRealIndex(0);
    setIsAnswerView(false);
  }, []);

  const onMenuButtonClick = useCallback(() => {
    setIsSideMenu((prev) => !prev);
  }, []);

  return (
    <MuiProvider>
      <BaseLayout>
        {!isFullScreen && (
          <Appbar>
            <Appbar.BackButton onClick={onBackButtonClick} />
            <Appbar.Title title={"데모 교재"} />
            <Appbar.MenuButton onClick={onMenuButtonClick} />
          </Appbar>
        )}
        {isSideMunu && <SideMenu />}
        <PdfQuestionToolbar />
        <PdfViewerContainer>
          <PanelGroupContainer
            topItem={<PdfViewer fileUrl="/example.pdf" />}
            bottomItem={isAnswerView && <PdfViewer fileUrl="/example.pdf" />}
          />
        </PdfViewerContainer>
      </BaseLayout>
    </MuiProvider>
  );
};

export default PDFdemo;
