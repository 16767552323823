import { realIndexAtom } from "application/recoils/swiper/atoms";
import { fullSreenAtom } from "application/recoils/ui/atoms";
import { Stack, Typography } from "@mui/material";
import { IcArrowLeft } from "components/atoms/icons/arrowLeft";
import { IcArrowRight } from "components/atoms/icons/arrowRight";
import { IcGoEnd } from "components/atoms/icons/goEnd";
import { IcGoStart } from "components/atoms/icons/goStart";
import { PaginationInput } from "components/atoms/paginationInput";
import React, { JSX, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import type { QuestionPaginationProps } from "./types";
import { CommonContext } from "../../../asset/context";

export function QuestionPagination({ questionSwiper }: QuestionPaginationProps): JSX.Element {
  const isFullScreen = useRecoilValue(fullSreenAtom);
  const [realIndex, setRealIndex] = useRecoilState(realIndexAtom);
  const [displayIndex, setDisplayIndex] = useState<number>(1);
  const { bookInQuestionList } = useContext(CommonContext);

  useEffect(() => {
    setDisplayIndex(realIndex + 1);
  }, [realIndex]);

  const totalPage = useMemo((): number => {
    if (!bookInQuestionList) {
      return 0;
    }
    return bookInQuestionList.length;
  }, [bookInQuestionList]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const numDisplayIndex = parseInt(e.target.value, 10);
    setDisplayIndex(numDisplayIndex);
  }, []);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === "Enter") {
        const newRealIndex = displayIndex - 1;
        setRealIndex(newRealIndex);
        questionSwiper.slideTo(newRealIndex);
      }
    },
    [displayIndex, questionSwiper]
  );

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row" gap="8px">
      <button type="button" aria-label="go-start" onClick={() => questionSwiper!.slideTo(0, 0)}>
        <IcGoStart />
      </button>
      <button type="button" aria-label="go-end" onClick={() => questionSwiper.slidePrev()}>
        <IcArrowLeft color={isFullScreen ? "#fff" : undefined} />
      </button>
      <Stack alignItems="center" justifyContent="space-between" direction="row" gap="8px">
        <PaginationInput onChange={handleInputChange} onKeyUp={onKeyUp} value={displayIndex} />
        <Typography fontSize={12} color={isFullScreen ? "#fff" : "inherit"}>
          {" "}
          / {totalPage}
        </Typography>
      </Stack>
      <button type="button" aria-label="go-next" onClick={() => questionSwiper.slideNext()}>
        <IcArrowRight color={isFullScreen ? "#fff" : undefined} />
      </button>
      <button
        type="button"
        aria-label="go-end"
        onClick={() => questionSwiper!.slideTo(totalPage! - 1, 0)}
      >
        <IcGoEnd />
      </button>
    </Stack>
  );
}
