export enum QueryKeys {
  // book
  BOOK_BY_ID = "bookById",
  ANSWER_BY_BOOKID = "answerByBookID",
}

export enum MutationKeys {
  // search
  SEARCH_ANSWER = "searchAnswer",
}
