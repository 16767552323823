import { Box, Typography } from "@mui/material";
import { AntSwitch } from "../../../components/organisms/answerSwitch/styles";
import React, { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { answerViewAtom, fullSreenAtom } from "../../../application/recoils/ui/atoms";

const PdfAnswerSwitch = () => {
  const isFullScreen = useRecoilValue(fullSreenAtom);
  const [isAnswerView, setIsAnswerView] = useRecoilState(answerViewAtom);
  const handleSwitch = useCallback((): void => {
    setIsAnswerView((prev) => !prev);
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
      <Typography fontSize="12px" color={isFullScreen ? "#fff" : undefined}>
        해설보기
      </Typography>
      <AntSwitch
        checked={isAnswerView}
        onChange={handleSwitch}
        inputProps={{ "aria-label": "controlled" }}
        isfullscreen={isFullScreen.toString()}
      />
    </Box>
  );
};
export default PdfAnswerSwitch;
