import { createTheme, CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import React from "react";
import { globalStyles } from "./globals";
import { backgroudPalette, neutralPalette } from "./options";
import type { MuiProviderProps } from "./types";

export function MuiProvider({ children }: MuiProviderProps): JSX.Element {
  const theme = createTheme({
    palette: {
      primary: {
        light: neutralPalette[40],
        main: neutralPalette[50],
        dark: neutralPalette[70],
      },
      background: {
        default: backgroudPalette.default,
        paper: backgroudPalette.paper,
      },
      text: {
        primary: neutralPalette[50],
      },
    },
    typography: {
      fontFamily: ["SpoqaHanSansNeo", "sans-serif"].join(","),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'SpoqaHanSansNeo';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('SpoqaHanSansNeo'), local('SpoqaHanSansNeo-Regular'), url('assets/fonts/SpoqaHanSansNeo-Regular.woff2') format('woff2');
          }
        `,
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: "24px",
            paddingRight: "24px",
            "@media (max-width:412px)": {
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 375,
        sm: 390,
        md: 412,
        lg: 820,
        xl: 1180,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles(theme)} />
      {children}
    </ThemeProvider>
  );
}
