import { styled } from '@mui/material';
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: '#fff',
    backgroundColor: '#252525',
    boxShadow: '4px 4px 6px 0px #0000001A',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '.MuiMenuItem-divider': {
      borderColor: '#fff',
    },
  },
}));
