import type { IcDefatultProps } from "../types";
import React from "react";

export function IcGoEnd({
  width = 16,
  height = 16,
  color = "#BFBFBF",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.35355 13.5256C4.15829 13.3303 4.15829 13.0137 4.35355 12.8185L8.4865 8.6855C8.68176 8.49024 8.68176 8.17366 8.4865 7.97839L4.35355 3.84543C4.15829 3.65017 4.15829 3.33359 4.35355 3.13833L4.47182 3.02006C4.66708 2.82479 4.98366 2.82479 5.17893 3.02006L10.1373 7.97839C10.3325 8.17365 10.3325 8.49024 10.1373 8.6855L5.17893 13.6438C4.98367 13.8391 4.66708 13.8391 4.47182 13.6438L4.35355 13.5256Z"
        fill={color}
      />
      <path
        d="M12 4.16283C12 3.88669 11.7761 3.66283 11.5 3.66283H11.3327C11.0566 3.66283 10.8327 3.88669 10.8327 4.16283V12.5011C10.8327 12.7772 11.0566 13.0011 11.3327 13.0011H11.5C11.7761 13.0011 12 12.7772 12 12.5011V4.16283Z"
        fill={color}
      />
    </svg>
  );
}
