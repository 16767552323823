import React from "react";
import { IcMenu } from "../../atoms/icons/menu";
import type { MenuButtonProps } from "./types";

export function MenuButton({ iconColor, ...props }: MenuButtonProps): JSX.Element {
  return (
    <button type="button" {...props}>
      <IcMenu color={iconColor} />
    </button>
  );
}
