export enum AtomKeys {
  // ui
  ANSWER_VIEW = "answer_view",
  FULL_SCREEN = "full_screen",
  ACTION_BUTTONS = "actions_buttons",
  SIDE_MENU = "side_menu",

  // swiper
  SWIPER_SLIDE_REAL_INDEX = "swiper_slide_real_index",
  SIWPER_IS_ZOOMED = "swiper_is_zoomed",

  // book (qna)
  QUESTION_ID = "question_id",

  // search
  SEARCH_HISTORY = "search_history",
}
