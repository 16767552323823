import { Box } from "@mui/material";
import { MoreButton } from "components/molecules/moreButton";
import React, { useMemo } from "react";
import { IcDownload } from "../../atoms/icons/download";
import { IcRotate } from "../../atoms/icons/download copy";
import { IcFullList } from "../../atoms/icons/fullList";
import { FullScreenButton } from "../fullScreenButton";
import type { ActionButtonsProps } from "./types";
import { showToast } from "../../../providers/ToastProvider";
import constants from "../../../asset/constants";
import store from "../../../asset/store";
import ImageRotateModal from "../../../pages/StudyBookDetail/components/BookDetail/components/PdfStudyBookDetail/components/ImageRotateModal";
import PdfAllViewModal from "../../../pages/PdfView/components/PdfAllViewModal";

export function ActionButtons({
  isShow,
  data,
  swiper,
  isQuestion,
}: ActionButtonsProps): JSX.Element {
  const [isEditImg, setIsEditImg] = React.useState(false);
  const [isAllPage, setIsAllPage] = React.useState(false);

  const imageSrc = useMemo(() => {
    return `${constants.s3_url}/qna/${data?.qid_HIQDB_online_qna?.img}.jpg`;
  }, [data]);
  const href = store.isMobile()
    ? `http://native_download?filename=${swiper?.activeIndex}.jpg?filepath=${imageSrc}`
    : imageSrc;

  return (
    <Box
      sx={{
        display: isShow ? "flex" : "none",
        justifyContent: "space-between",
        gap: "10px",
        position: "absolute",
        right: "40px",
        top: "8px",
        zIndex: 1,
        opacity: isShow ? 1 : 0,
        transition: "opacity 0.3s ease-out",
      }}
      onClick={(e) => {}}
    >
      <MoreButton
        items={[
          {
            title: "현재 페이지 다운로드",
            icon: <IcDownload />,
            onClick: () => {
              const a = document.createElement("a");
              a.href = href;
              a.download = data.qid_HIQDB_online_qna.img;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              showToast({
                title: "다운로드",
                message: "이미지를 다운로드 합니다.",
                type: "success",
              });
            },
          },
          {
            title: "이미지 편집",
            icon: <IcRotate />,
            onClick: () => {
              setIsEditImg(true);
            },
          },
          {
            title: "전체 페이지",
            icon: <IcFullList />,
            onClick: () => {
              console.log("전체 페이지");
              setIsAllPage(true);
            },
          },
        ]}
      />
      <FullScreenButton />
      {isEditImg && (
        <ImageRotateModal
          currentSliderInfo={data}
          open={isEditImg}
          onClose={() => setIsEditImg(false)}
          onSuccess={() => {
            setIsEditImg(false);
          }}
          title={data?.qid_HIQDB_online_qna?.title}
        />
      )}
      {isAllPage && (
        <PdfAllViewModal
          open={isAllPage}
          onClose={() => setIsAllPage(false)}
          currentSlide={swiper?.activeIndex}
          swiper={swiper}
          isQuestion={isQuestion}
        />
      )}
    </Box>
  );
}
