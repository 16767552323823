import { PdfViewerProps } from "./types";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import React from "react";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

export function PdfViewer({ fileUrl }: PdfViewerProps) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />;
}
