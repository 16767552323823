import React from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import { PanelResizeHandler } from "../../molecules/panelResizeHandler";
import type { PanelGroupContainerProps } from "./types";

export default function PanelGroupContainer({
  topItem,
  bottomItem,
  defaultSize = 50,
  maxSize = 50,
  minSize = 20,
}: PanelGroupContainerProps): JSX.Element {
  return (
    <PanelGroup
      autoSaveId="pdfview"
      direction="vertical"
      style={{ ...(!bottomItem && { overflow: undefined }) }}
    >
      <Panel
        order={1}
        minSize={minSize}
        maxSize={maxSize}
        defaultSize={defaultSize}
        style={{
          overflowY: bottomItem ? "scroll" : undefined,
          ...(!bottomItem && { overflow: undefined }),
        }}
      >
        {topItem}
      </Panel>
      {bottomItem && (
        <>
          <PanelResizeHandler />
          <Panel
            order={2}
            defaultSize={defaultSize}
            style={{
              overflowY: "scroll",
            }}
          >
            {bottomItem}
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}
