import type { IcDefatultProps } from "../types";
import React from "react";

export function IcDownload({
  width = 16,
  height = 16,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83333 4.8335V11.5002M8.83333 11.5002L6.83333 9.8335M8.83333 11.5002L10.8333 9.8335M6.5 13.8335H11.1667"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
