import type { IcDefatultProps } from "../types";
import React from "react";

export function IcGoStart({
  width = 16,
  height = 16,
  color = "#BFBFBF",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3548 3.13832C11.55 3.33359 11.55 3.65017 11.3548 3.84543L7.22181 7.97839C7.02655 8.17365 7.02655 8.49023 7.22181 8.68549L11.3548 12.8184C11.55 13.0137 11.55 13.3303 11.3548 13.5256L11.2365 13.6438C11.0412 13.8391 10.7246 13.8391 10.5294 13.6438L5.57103 8.68549C5.37577 8.49023 5.37577 8.17365 5.57103 7.97839L10.5294 3.02005C10.7246 2.82479 11.0412 2.82479 11.2365 3.02005L11.3548 3.13832Z"
        fill={color}
      />
      <path
        d="M4.87559 4.16283C4.87559 3.88669 4.65173 3.66283 4.37559 3.66283H4.20831C3.93217 3.66283 3.70831 3.88669 3.70831 4.16283V12.501C3.70831 12.7772 3.93217 13.001 4.20831 13.001H4.37559C4.65173 13.001 4.87559 12.7772 4.87559 12.501V4.16283Z"
        fill={color}
      />
    </svg>
  );
}
