import { useGetAnswersByBookId } from "application/hooks/apis/book";
import { answerViewAtom, fullSreenAtom } from "application/recoils/ui/atoms";
import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AntSwitch } from "./styles";
import type { AnswerSwitchProps } from "./types";
import { showToast } from "../../../providers/ToastProvider";

export function AnswerSwitch({ bookId }: AnswerSwitchProps): JSX.Element {
  const strBookId = bookId?.toString();
  const isFullScreen = useRecoilValue(fullSreenAtom);
  const [isAnswerView, setIsAnswerView] = useRecoilState(answerViewAtom);
  // NOTE: 해설이 있는 경우에만 switch를 활성화하기 위해 query 실행
  const { data, isLoading, isFetching } = useGetAnswersByBookId(strBookId);

  const onDisabled = useMemo((): boolean => {
    if (data && data.length === 0) {
      showToast({
        title: "이런,이 교재에는 아직 해설이 없어요.",
        message: "해설을 등록해주세요",
        type: "warning",
      });
    }
    if (isLoading || isFetching || (data && data.length === 0)) {
      return true;
    }
    return false;
  }, [data, isLoading, isFetching]);

  const handleSwitch = useCallback((): void => {
    setIsAnswerView((prev) => !prev);
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
      <Typography fontSize="12px" color={isFullScreen ? "#fff" : undefined}>
        해설보기
      </Typography>
      <AntSwitch
        checked={isAnswerView}
        onChange={handleSwitch}
        inputProps={{ "aria-label": "controlled" }}
        disabled={onDisabled}
        isfullscreen={isFullScreen.toString()}
      />
    </Box>
  );
}
