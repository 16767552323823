import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IcArrowLeft } from "../../atoms/icons/arrowLeft";
import type { BackButtonProps } from "./types";

export function BackButton({ iconColor, onClick, ...props }: BackButtonProps): JSX.Element {
  const navigate = useNavigate();

  const handleClick = useCallback((): void => {
    navigate(-1);
    if (onClick) {
      onClick();
    }
  }, []);

  return (
    <button type="button" onClick={handleClick} {...props}>
      <IcArrowLeft color={iconColor} width={24} height={24} />
    </button>
  );
}
