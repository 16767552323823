import { atom } from "recoil";
import { AtomKeys } from "../constants";
import type { ActionButtonsType } from "./types";

export const answerViewAtom = atom<boolean>({
  key: AtomKeys.ANSWER_VIEW,
  default: false,
  // effects: [localForageEffect(AtomKeys.ANSWER_VIEW)],
});

export const actionButtonsAtom = atom<ActionButtonsType>({
  key: AtomKeys.ACTION_BUTTONS,
  default: {
    question: false,
    answer: false,
  },
});

export const fullSreenAtom = atom<boolean>({
  key: AtomKeys.FULL_SCREEN,
  default: false,
  // effects: [localForageEffect(AtomKeys.FULL_SCREEN)],
});
export const sideMenuAtom = atom<boolean>({
  key: AtomKeys.SIDE_MENU,
  default: false,
});
