import type { IcDefatultProps } from "../types";
import React from "react";

export function IcFullList({
  width = 16,
  height = 16,
  color = "white",
}: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="2.5" width="4.33333" height="4.33333" rx="0.5" stroke={color} />
      <rect x="2.5" y="9.1665" width="4.33333" height="4.33333" rx="0.5" stroke={color} />
      <rect x="9.16663" y="2.5" width="4.33333" height="4.33333" rx="0.5" stroke={color} />
      <rect x="9.16663" y="9.1665" width="4.33333" height="4.33333" rx="0.5" stroke={color} />
    </svg>
  );
}
