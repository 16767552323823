import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import "swiper/css/effect-fade";
import type { ElementRef } from "react";
import React, { forwardRef } from "react";
import { Swiper } from "swiper/react";
import type { SwiperContainerProps } from "./types";

export const SwiperContainer = forwardRef<ElementRef<typeof Swiper>, SwiperContainerProps>(
  (props, ref) => {
    return (
      <Swiper ref={ref} {...props} touchStartPreventDefault={false}>
        {props.children}
      </Swiper>
    );
  }
);

SwiperContainer.displayName = "SwiperContainer";
