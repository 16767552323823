import { useGetAnswersByBookId } from "application/hooks/apis/book";
import { useSwiperNavigationStyle } from "application/hooks/libs/swiper";
import { useToast } from "application/hooks/libs/toast";
import { useSearchAnswer } from "application/hooks/logics/search";
import { questionIdAtom } from "application/recoils/book/atoms";
import { searchHistoryAtom } from "application/recoils/search/atoms";
import { actionButtonsAtom } from "application/recoils/ui/atoms";
import { Box } from "@mui/material";
import { SwiperContainer } from "components/molecules/swiperContainer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import type SwiperClass from "swiper";
import { Navigation, Zoom } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import { LoadingView } from "../../templates/loadingView";
import { ActionButtons } from "../actionButtons";
import { QnASlideItem } from "../qnaSlideItem";
import type { AnswerSwiperProps } from "./types";

export function AnswerSwiper({ bookId }: AnswerSwiperProps): JSX.Element | null {
  const strBookId = bookId.toString();
  const { data, isLoading, isFetching } = useGetAnswersByBookId(strBookId);
  const { handleSearchAnswer } = useSearchAnswer();
  const [answerSwiper, setAnswerSwiper] = useState<SwiperClass | null>(null);
  const questionId = useRecoilValue(questionIdAtom);
  const searchHistory = useRecoilValue(searchHistoryAtom);
  const [actionButtons, setShowActionsButtons] = useRecoilState(actionButtonsAtom);
  const { showInfo } = useToast();
  const { changeNavOpacity, changeNavOpacityOnClick } = useSwiperNavigationStyle();

  const req = useMemo(() => {
    return {
      userId: "0YtcNZweMgR0PkembMND",
      userType: 2,
      option: "exemplary",
      searchType: "question",
      limit: 1,
      searchData: questionId,
      bookId: strBookId,
      isSolution: true,
    };
  }, [questionId, strBookId]);

  useEffect(() => {
    if (!data) {
      showInfo("해설이 없는 교재입니다.");
      return;
    }
    if (!answerSwiper) {
      return;
    }
    const searched = searchHistory.find((h) => h.questionId === questionId);
    if (searched) {
      const index = data!.findIndex((d) => d.qid_HIQDB_online_qna.id === searched.answerId);
      answerSwiper.slideTo(index, 0);
      return;
    }
    handleSearchAnswer(req, answerSwiper, data);
  }, [data, answerSwiper, req, searchHistory, questionId]);

  useEffect(() => {
    if (!answerSwiper) {
      return;
    }
    changeNavOpacityOnClick(answerSwiper);
  }, [answerSwiper]);

  const handleSwiperClick = useCallback((swiper: SwiperClass) => {
    setShowActionsButtons((prev) => ({ ...prev, answer: !prev.answer }));
    changeNavOpacity(swiper, "toggle");
  }, []);

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    setShowActionsButtons((prev) => ({
      ...prev,
      answer: prev.answer === true ? false : prev.answer,
    }));
    changeNavOpacity(swiper, "invisible");
  }, []);

  if (!data) {
    return null;
  }

  if (isLoading || isFetching) {
    return <LoadingView />;
  }

  return (
    <Box sx={{ position: "relative" }} className="no-drag">
      <SwiperContainer
        className="answer-swiper-container"
        slidesPerView={1}
        modules={[Navigation, Zoom]}
        navigation
        zoom
        spaceBetween={0}
        onSwiper={(swiper: SwiperClass) => setAnswerSwiper(swiper)}
        onClick={(swiper: SwiperClass) => handleSwiperClick(swiper)}
        onSlideChange={(swiper: SwiperClass) => handleSlideChange(swiper)}
      >
        {data!.map((d) => {
          return (
            <SwiperSlide key={d.qid} data-aid={d.qid_HIQDB_online_qna.id}>
              <QnASlideItem data={d} />
            </SwiperSlide>
          );
        })}
      </SwiperContainer>
      <ActionButtons
        isShow={actionButtons.answer}
        data={data[answerSwiper?.activeIndex]}
        swiper={answerSwiper}
        isQuestion={false}
      />
    </Box>
  );
}
