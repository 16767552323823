import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WarningIcon } from "asset/images/icon/alert-triangle.svg";

export function EmptyView(): JSX.Element {
  const navigate = useNavigate();

  const goBack = useCallback((): void => {
    navigate(-1);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack direction="column" alignItems="center">
        <WarningIcon />
        <Typography mt={2} mb={1} color="primary.main">
          등록된 문제가 없어요! 문제를 등록해주세요.
        </Typography>
      </Stack>
    </Box>
  );
}
