import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";
import { IcVerticalAlign } from "components/atoms/icons/verticalAlign";
import React from "react";
import { PanelResizeHandle } from "react-resizable-panels";

export function PanelResizeHandler(): JSX.Element {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <PanelResizeHandle style={{ width: "100%", position: "relative" }}>
      <Box
        sx={{
          width: "96px",
          height: "40px",
          backgroundColor: "primary.dark",
          margin: "0 auto",
          borderRadius: 99,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10,
          [theme.breakpoints.down("md")]: {
            width: "64px",
            height: "24px",
          },
        }}
      >
        <IcVerticalAlign width={isMdDown ? 16 : 24} height={isMdDown ? 16 : 24} />
      </Box>
      <Divider sx={{ position: "absolute", top: "50%", width: "100%", zIndex: 10 }} />
    </PanelResizeHandle>
  );
}
