import { zoomAtom } from "application/recoils/swiper/atoms";
import { answerViewAtom } from "application/recoils/ui/atoms";
import React from "react";
import { useRecoilValue } from "recoil";
import type { QnASlideItemProps } from "./types";

export function QnASlideItem({ data }: QnASlideItemProps): JSX.Element {
  const isZoomed = useRecoilValue(zoomAtom);
  const isAnswerView = useRecoilValue(answerViewAtom);

  return (
    <div className="swiper-zoom-container">
      <img
        src={`${process.env.REACT_APP_S3_URL}/qna/${data.qid_HIQDB_online_qna.img}.jpg`}
        alt={data.qid_HIQDB_online_qna.title}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          ...(!isAnswerView && isZoomed && { objectFit: "fill" }),
        }}
        loading="lazy"
        data-id={data.qid}
        onDragStart={(e) => e.preventDefault()}
        onClick={(e) => e.stopPropagation()}
      />
      <div className="swiper-lazy-preloader" />
    </div>
  );
}
