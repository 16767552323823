import type { IcDefatultProps } from "../types";
import React from "react";

export function IcMore({ width = 24, height = 24, color = "white" }: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5.5" cy="12.5" r="1.5" fill={color} />
      <circle cx="12.5" cy="12.5" r="1.5" fill={color} />
      <circle cx="19.5" cy="12.5" r="1.5" fill={color} />
    </svg>
  );
}
