import { PdfViewerContainerProps } from "./types";
import { Worker } from "@react-pdf-viewer/core";
import React from "react";

export function PdfViewerContainer({ children }: PdfViewerContainerProps): JSX.Element {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      {children}
    </Worker>
  );
}
