import type { RequsetSearchAnswer } from "api/search/dtos";
import { searchAnswer } from "api/search/services";
import { useBaseMutation } from "../base/use_base_mutation";
import { MutationKeys } from "../constants";
import type { SearchAnswerType } from "./types";

export function useSearchAnswerByReq(): SearchAnswerType {
  const { data, isError, mutate, mutateAsync } = useBaseMutation({
    mutationKey: [MutationKeys.SEARCH_ANSWER],
    mutationFn: (req: RequsetSearchAnswer) => searchAnswer(req),
  });

  return {
    data,
    isError,
    mutate,
    mutateAsync,
  };
}
