/* eslint-disable func-names */
import { useCallback } from "react";
import type SwiperClass from "swiper";
import type { SwiperNavigationStyleTypes } from "./types";

export function useSwiperNavigationStyle(): SwiperNavigationStyleTypes {
  const changeNavOpacity = useCallback((swiper: SwiperClass, type: "toggle" | "invisible") => {
    if (!swiper.navigation) {
      return;
    }

    const { prevEl, nextEl } = swiper.navigation;
    if (!prevEl || !nextEl) {
      return;
    }

    const buttons = [prevEl, nextEl];
    buttons.forEach((button) => {
      const { style } = button;
      const currentOpacity = style.opacity || "0";
      if (type === "toggle") {
        style.opacity = currentOpacity === "0" ? "1" : "0";
        return;
      }
      style.opacity = "0";
    });
  }, []);

  const changeNavOpacityOnClick = useCallback((swiper: SwiperClass) => {
    if (!swiper.navigation) {
      return;
    }

    const { prevEl } = swiper.navigation;
    const { nextEl } = swiper.navigation;
    if (!prevEl || !nextEl) {
      return;
    }

    const buttons = [prevEl, nextEl];
    buttons.forEach((button) => {
      button.addEventListener("click", function (this: HTMLButtonElement) {
        this.style.opacity = "1";
        setTimeout(() => {
          this.style.opacity = "0";
        }, 300);
      });
    });
  }, []);

  return {
    changeNavOpacity,
    changeNavOpacityOnClick,
  };
}
